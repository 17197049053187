import { InvoiceServiceService } from './../../service/invoice-service.service';
import { EStatusCode } from './../../service/Utils/Interfaces.class';
import { TenantCompaniesService } from './../../service/tenant-companies.service';
import { environment } from './../../../environments/environment';
import { TsysService } from './../../service/tsys.service';
import { COMMON, ToastMessages } from './../../service/constant';
import { LoginService } from './../../service/login.service';
import { SetupService } from './../../service/setup.service';
import { ModuleListService } from './../../service/module-list.service';
import { Component, OnInit, ViewChild, Injector, NgZone, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, FormArray, FormControl } from '@angular/forms';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from 'ngx-google-places-autocomplete';
import { AddressComponent } from 'ngx-google-places-autocomplete';
import { NgOption, NgSelectModule } from "@ng-select/ng-select";
import { AppComponentBase } from '../../admin/shared/AppComponentBase';
import moment from "moment";
import CryptoJS from "crypto-js";
import { Subscription } from 'rxjs';
import {GlobalService} from "../../service/global.service";
import {PaymentInfoService} from "../../service/payment-info.service";
import { SocketService } from '../../service/socket.service';
import { MatStepper, MatStep, MatStepLabel, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import { CommonDatePipe } from '../../admin/shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GooglePlaceDirective as GooglePlaceDirective_1 } from '../../../../../libs/third-party-deprecated/src/lib/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgFor, DecimalPipe, TitleCasePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
    standalone: true,
    imports: [MatButton, MatStepper, MatStep, MatStepLabel, FormsModule, ReactiveFormsModule, NgSelectModule, NgClass, ExtendedModule, MatFormField, MatLabel, MatInput, MatStepperNext, GooglePlaceDirective_1, NgIf, MatStepperPrevious, NgFor, MatCheckboxModule, MatRadioGroup, MatRadioButton, SpinnerComponent, DecimalPipe, TitleCasePipe, CommonDatePipe]
})
export class WizardComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  isEditable = false;
  submitted = false;
  public moduleList: any;
  public modules: any;
  checkedListModule: any = [1, 2, 5, 6, 7, 39, 65, 76, 104];
  selectedModuleArr: any = [];
  amountsArr: any = [];
  isDisabledCheck: boolean = false;
  paymentInfoFlag: boolean = false;
  public configData: any;
  submit = false;
  checkCompany = false;
  country;
  city = '';
  state = '';
  zip = '';
  userId: string;
  itemTaxRate1: any;
  unitCost: number = 0;
  paymentStatus: number = 0;
  defaultTaxId : number;
  addressLine1 = '';
  address = '';
  public check : boolean = true;
  public showError: boolean;
  public showError1: boolean;
  public errorMsg :  string;
  public errorMsg1: string;
  public orientation: 'horizontal' | 'vertical' = 'horizontal';
  billingZipcode: number;

  get f() {
    return this.firstFormGroup.controls;
  }

  companyTypeList: NgOption = [
    {
      'value': 'S Corp', 'label': 'S Corp'
    },
    {
      'value': 'C Corp', 'label': 'C Corp'
    },
    {
      'value': 'LLC', 'label': 'LLC'
    }
  ];

  industryList: NgOption = [
    {
      'value': 'Legal Services', 'label': 'Legal Services'
    },
    {
      'value': 'Professional Services', 'label': 'Professional Services'
    },
    {
      'value': 'Medical, Dental, Health Services', 'label': 'Medical, Dental, Health Services'
    },
    {
      'value': 'Construction - Electrician, Plumbers, HVAC, Security', 'label': 'Construction - Electrician, Plumbers, HVAC, Security'
    },
    {
      'value': 'Information Technology (I.T.)', 'label': 'Information Technology (I.T.)'
    },
    {
      'value': 'Product Based Company', 'label': 'Product Based Company'
    },
    {
      'value': 'Service Based Company', 'label': 'Service Based Company'
    },
    {
      'value': 'Product & Service Based Company', 'label': 'Product & Service Based Company'
    }
  ];

  industryData: NgOption = JSON.parse(JSON.stringify(this.industryList));
  companyData: NgOption = JSON.parse(JSON.stringify(this.companyTypeList));
  mid: number;
  deviceId: number;
  transactionKey: string;
  padString = "0";
  encryptedTSEP: string;
  tsysFlag: boolean = false;
  currentStatus: number = 2;
  defaultCC: number = 1;
  totalSeats: number = 0;
  remainingDaysToEndMonth: number = 0;
  reservedSeatInfo: any;
  itemTax: number = 0;
  subTotal: number = 0;
  taxSubTotal: number = 0;
  dataArr = [];
  pucost: number = 0;
  mcost: number = 0;
  discount: number = 0;
  total_cost: number = 0;
  zerocost: number = 0;
  sTotal: any = 0;
  displaytax: any = 0;
  tax: number = 0;
  displaydiscount: any = 0;
  total: number = 0;
  radioValue: number = 1;
  subscriptionValue: number = 1;
  subscriptionSelected: boolean = false;
  alreadyVisited: boolean = false;
  displaytotal: any = 0;
  calculationObject: any;

  constructor(private _formBuilder: FormBuilder, private globalService: GlobalService,
    private inject: Injector,
    private moduleListService: ModuleListService,private paymentInfoService: PaymentInfoService,
    private setupService: SetupService,
    private loginService: LoginService,
    private zone: NgZone,
    private socketService: SocketService,
    public tsysService: TsysService,
    private elementRef: ElementRef,
    public tenantCompaniesService: TenantCompaniesService,
    public invoiceService: InvoiceServiceService,
    private readonly breakpointObserver: BreakpointObserver
    ) {
      super(inject);
      this.configService.updateIsUserLogged.next(false);

      window["angularComponentDialogReference"] = {
        zone: this.zone,
        componentFn: value => this.reopenDialog(value),
        component: this
      };

      window["angularComponentReference"] = {
        zone: this.zone,
        componentFn: value => this.updateTsysData(value),
        component: this
      };
  }

  ngOnInit() {
    this.wizardFormValidation();
    this.getTsysDetails();
    this.getDefaultTaxId();
    this.userId = localStorage.getItem("userId");
    var setupDone = localStorage.getItem('setup');
    if (setupDone == "1") {
      this.router.navigate(["/admin/dashboard"], { replaceUrl: true });
      this.getModuleList();
    } else if (localStorage.getItem("userId") === null) {
      this.router.navigate(["/login"], { replaceUrl: true });
    } else if(setupDone == "0"){
      this.getModuleList();
    }
    if(!this.firstFormGroup.value.industry){
      this.firstFormGroup.controls['industry'].reset();
    }
    if(!this.firstFormGroup.value.companyType){
      this.firstFormGroup.controls['companyType'].reset();
    }
    this.getReservedSeats();
    this.getReserveSeatItemDetail();
    this.loadJs("../../../assets/js/tsys-tsep.js");
    this.breakpointObserver.observe([
      '(max-width: 640px)'
    ]).subscribe(result => {
      this.orientation = result.matches ? 'vertical' : 'horizontal';
    });
  }

  loadJs(url: string){
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  getDefaultTaxId(){
    const getASConfigById : Subscription =  this.tsysService.getConfigById('default_tax').subscribe((data:any)=>{
      if(data.result.info.code === EStatusCode.OK){
        this.defaultTaxId = data.result.data[0].value;
      }
    })
    this.subscriptions.push(getASConfigById);
  }

  getReservedSeats() {
    const getASReservedSeats : Subscription = this.tenantCompaniesService.getReservedSeats().subscribe((response: any) => {
      if (response.info.code === EStatusCode.OK) {
        this.totalSeats = response.data[0].noOfUsers;
      }
    });
    this.subscriptions.push(getASReservedSeats);
  }

  getReserveSeatItemDetail(){
    this.paymentInfoService.reservedSeatItemDetails().toPromise().then((itemDetails: any) => {
      if (itemDetails.info.code === EStatusCode.OK) {
        this.reservedSeatInfo = itemDetails.data[0];
      }
    });
  }

  loadScript(url) {
    let node = document.createElement("script");
    node.src = url;
    node.type = "text/javascript";
    this.elementRef.nativeElement.appendChild(node);
  }

  reopenDialog(value) {
    $("#tsep-cardNumDiv").empty();
    $("#tsep-datepickerDiv").empty();
    $("#tsep-cvv2Div").empty();
    this.currentStatus = 2;
    this.getTsysDetails();
    this.loadScript(environment.TSYS_MANIFEST_URL + this.deviceId +"?" + this.encryptedTSEP);
    $("#div_console").empty();
  }

  defaultCcCheck(event) {
    if (event.checked) {
      this.defaultCC = 1;
    } else {
      this.defaultCC = 0;
    }
  }

  radioChange(event){
    if (event.value == '1') {
      // this.subscriptionValue = 0;
      this.subscriptionSelected = false;
      this.paymentStatus = 1;
    } else {
      // this.subscriptionValue = 1;
      this.paymentStatus = 2;
      this.subscriptionSelected = true;

    }
  }

  selectSubscriptionType(event){
    var date = new Date()
    this.remainingDaysToEndMonth = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate())+1;
    if (event == 1) {
      console.log(this.calculationObject);
      
      this.subscriptionValue = 1;
      this.total = this.calculationObject[0].total;      
      this.sTotal = this.calculationObject[0].subTotal
      this.displaytotal = this.calculationObject[0].total
      this.displaytax = this.calculationObject[0].tax
      this.displaydiscount = this.calculationObject[0].discount
    } else {
      this.subscriptionValue = 2;
      this.sTotal = this.calculationObject[1].subTotal
      this.displaytotal = this.calculationObject[1].total
      this.displaytax = this.calculationObject[1].tax
      this.displaydiscount = this.calculationObject[1].discount
      this.total = this.calculationObject[1].total;      
    }
  }

  updateTsysData(tsysData) {
    this.tsysService.updateTsysData(tsysData);
    this.currentStatus = 1;
  }

  getTsysDetails(){
    const getASTsysInfo : Subscription = this.globalService.getTsysInfo().subscribe((res:any)=>{
      if (res.result.info.code === EStatusCode.OK) {
        this.mid = res.result.data.mid;
        this.transactionKey = res.result.data.transactionKey;
        this.deviceId = res.result.data.deviceId;
        this.encryptedTSEP = this.encryptManifest(
          this.mid,
          this.deviceId,
          this.transactionKey
        );
        // this.getloadScript();
        this.tsysFlag = true;
      } else {
        this.tsysFlag = false;
      }
      
    })
    this.subscriptions.push(getASTsysInfo);
  }
  encryptManifest(mid, deviceId, transactionKey) {
    var dataString = this.pad(mid, 20) + this.pad(deviceId, 24) + "000000000000" + moment().format("MMDDYYYY");
    const key = CryptoJS.enc.Utf8.parse(transactionKey.substr(0, 16));
    const iv = key;
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding
    }).toString();
    var finalManifest = CryptoJS.enc.Base64.parse(encrypted);
    var hash = CryptoJS.HmacMD5(transactionKey, transactionKey);
    var finalhash = hash.toString(CryptoJS.enc.Hex);
    return finalhash.substr(0, 4) + finalManifest + finalhash.substr(-4);
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + " ";
    return s;
  }

  wizardFormValidation() {
    this.firstFormGroup = this._formBuilder.group({
      industry: ['', Validators.required],
      companyType: ['', Validators.required],
      fein: ['']

    });
    this.secondFormGroup = this._formBuilder.group({
      legalName: ['', Validators.required],
      address: ['', Validators.required],
      cityStateZip: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', Validators.compose([Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)])],
      website: ['']
    });
    this.thirdFormGroup = this._formBuilder.group({
      selectModules: this.fb.array([]),
      addPaymentInfo: ['']
    });
    this.fourthFormGroup = this._formBuilder.group({
      selectModules: [''],
      addPaymentInfo: ['']
    });
  }

  get selectModules(): FormArray {
    return this.thirdFormGroup.get('selectModules') as FormArray;
  }

  updateFormArray(): void {
    const formArray = this.selectModules;
    formArray.clear();
    this.moduleList.forEach(module => {
      formArray.push(new FormControl({value: module.ischecked === 1, disabled: module.isDisabledCheck}));
    });
  }

  getModuleList() {
    const getASDModules : Subscription = this.moduleListService.getModules().subscribe((res:any) => {
      var data = res.data;
      
      var dataLength = Object.keys(data).length
      for (let index = 0; index < dataLength; index++) {
        const element = data[index];
        // element.label = element.label.toLowerCase();
        if (element.label == 'dashboard' || element.label == 'calendar' || element.label == 'client' 
        || element.label == 'vendor' || element.label == 'tasks' || element.label == 'HR' 
        || element.label == 'report' || element.label == 'utilities' || element.label == 'subscription') {
          element.ischecked = 1;
          element.isDisabledCheck = true;
          element.costText = "$"+element.totalCost+"/m"
          this.selectedModuleArr.push(element)
        } else {
          element.ischecked = 0;
          element.isDisabledCheck = false;
          element.costText = "$"+element.totalCost+"/m/user"
        }
      }
      this.moduleList = data;
      this.updateFormArray();
    });
    this.subscriptions.push(getASDModules);
  }

  // Add new modules to subscribe module array
  onCheckboxChange(option, event) {
    if (event.checked) {
      this.checkedListModule.push(option.menuId);
      this.selectedModuleArr.push(option);
    }
    else {
      const indexCheckedList = this.checkedListModule.indexOf(option.menuId);
      const indexSelectedModule = this.selectedModuleArr.findIndex(module => module.menuId === option.menuId);
      if (indexCheckedList !== -1) {
        this.checkedListModule.splice(indexCheckedList, 1);
      }
      if (indexSelectedModule !== -1) {
        this.selectedModuleArr.splice(indexSelectedModule, 1);
      }
    }    
  }

  addPaymentInfo(event){
    if (event.checked) {
      this.alreadyVisited = false;
      this.currentStatus = 2;
      this.paymentInfoFlag = true;
    } else {
      this.paymentInfoFlag = false;
    }
  }


  updateIndustry() {
    this.submit = false;
  }
  updateCompany() {
    this.checkCompany = false;
  }


  form1() {
    if (!this.firstFormGroup.value.industry) {
      this.submit = true;
    } else {
      this.submit = false;
    }
    if (!this.firstFormGroup.value.companyType) {
      this.checkCompany = true;
    } else {
      this.checkCompany = false;
    }
  }

  form2(stepper: MatStepper): void {
    if (this.secondFormGroup.invalid || this.showError || this.showError1 || !this.check) {
      return;
    } else {
      stepper.next();
      this.globalService.getSubscriptionAmount(JSON.stringify(this.selectedModuleArr),JSON.stringify(this.reservedSeatInfo), this.totalSeats).subscribe((response: any) => {
        if (response.info.code === EStatusCode.OK) {
          this.amountsArr = response.data.total;
          this.calculationObject = response.data
          this.sTotal = response.data[0].subTotal
          this.displaytotal = response.data[0].total
          this.displaytax = response.data[0].tax
          this.displaydiscount = response.data[0].discount
        }
      });
    }
  }
  form3(stepper) {
    if (this.paymentInfoFlag) {
      stepper.next();

      this.radioValue = 2; // for subscription

      if (this.subscriptionValue == 2) {
        this.selectSubscriptionType(this.subscriptionValue)
      } else if(this.subscriptionValue == 1){
        this.selectSubscriptionType(this.subscriptionValue)
      } else {
        this.subscriptionSelected = false;
        this.radioValue = 1; // for free trial
      }

      if (this.currentStatus == 2 && !this.alreadyVisited) {
        this.alreadyVisited = true;
        this.loadScript(environment.TSYS_MANIFEST_URL + this.deviceId +"?" + this.encryptedTSEP);
      }
    } else {
      this.submitWizard()
    }
  }

  // Save CC for free trial
  submitWizard() {

    if (this.paymentInfoFlag) {
      if (!this.billingZipcode || String(this.billingZipcode).length < 5) {
        this.toastr.error(ToastMessages.INVALID_ZIPCODE)
      } else {
  
        let ccData = {
          userId: Number(this.userId),
          authData: this.tsysService.getTsysData(),
          isDefaultCard: this.defaultCC,
          saveDefault: this.defaultCC == 1 ? true : false
        };
        ccData.authData['zipCode'] = this.billingZipcode;
  
        const saveCCSub: Subscription = 
        this.globalService.userAuthentication(ccData).subscribe((saveCardRes: any) => {
          if (saveCardRes.info.code == EStatusCode.OK ||
            saveCardRes.info.code == EStatusCode.CREATED) {
              // card saved
              this.submitWizardForFreetrial()
          } else {
            // failed to save card
            this.toastr.error(saveCardRes.data.responseMsg)
          }
        });
        this.subscriptions.push(saveCCSub)
      }
    } else{
      this.submitWizardForFreetrial()
    }
    
  }

  submitWizardWithCC() {
    
    if (!this.billingZipcode || String(this.billingZipcode).length < 5) {
      this.toastr.error(ToastMessages.INVALID_ZIPCODE)
    } else {

    var address = this.secondFormGroup.value.address;
    var cityStateZip = this.secondFormGroup.value.cityStateZip.split(",");
    var city = cityStateZip[0];
    var state = cityStateZip[1];
    var zip = cityStateZip[2];
    var country = this.secondFormGroup.value.country;
    var companyEmail = this.secondFormGroup.value.email;
    var legalName = this.secondFormGroup.value.legalName;

    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.thirdFormGroup.invalid || this.fourthFormGroup.invalid) {
      return;
    } else {

      this.paymentInfoService.payNowEventListener.next(true);
      var ccData = {
        userId: Number(this.userId),
        authData: this.tsysService.getTsysData(),
        isDefaultCard: this.defaultCC,
        saveDefault: this.defaultCC == 1 ? true : false
      };
      ccData.authData['zipCode'] = this.billingZipcode;
  
      var payMentData = {
        creditCardNumber: ccData.authData.card_number,
        cardType: ccData.authData.card_type,
        expirationDate: ccData.authData.expiration_date,
        cvc: ccData.authData.cvc,
        billingZip: ccData.authData.zipCode
      };
  
      var amount = this.total;

      this.globalService
        .makeSubscribePayment(amount, payMentData)
        .subscribe((res: any) => {
          if (
            res.info.code == EStatusCode.OK ||
            res.info.code == EStatusCode.CREATED
          ) {
            var paymentInfo = res.data;
            /* SAVE CARD DATA */
            this.globalService
              .userAuthentication(ccData)
              .subscribe((saveCardRes: any) => {
  
                if (
                  saveCardRes.info.code == EStatusCode.OK ||
                  saveCardRes.info.code == EStatusCode.CREATED
                ) {
                  var cardInfo = saveCardRes.data.savedData;
                  /* Find lead details by company key */
                  var date = new Date();
                  var daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                  const getLDetilsByCompanyKey : Subscription =  this.globalService
                    .getLeadDetailsByCompanyKey()
                    .subscribe((leadRes: any) => {
                      if (
                        leadRes.info.code == EStatusCode.OK ||
                        leadRes.info.code == EStatusCode.CREATED
                      ) {
                        var leadInfo = leadRes.data;
                        var clientId = leadInfo.convertedClientId
                          let leadData: any = {};
                          /* Convert leads to client */
                          leadData.companyName = legalName;
                          leadData.locationName = leadInfo.leadName;
                          leadData.cemail = leadInfo.companyEmail ? leadInfo.companyEmail : companyEmail;
                          leadData.phone = leadInfo.phone;
                          leadData.billingaddress = leadInfo.address ? address : address;
                          leadData.billingCity = leadInfo.city ? city : city;
                          leadData.billingState = leadInfo.state ? state: state;
                          leadData.billingZipCode = leadInfo.zip ? leadInfo.zip : zip;
                          leadData.country = leadInfo.country ? leadInfo.country : country;
                          leadData.shippingAddress = leadInfo.address ? leadInfo.address : address;
                          leadData.shippingCity = leadInfo.city ? leadInfo.city : city;
                          leadData.shippingState = leadInfo.state ? leadInfo.state : state;
                          leadData.shippingZipCode = leadInfo.zip ? leadInfo.zip : zip;
                          leadData.sCountry = leadInfo.country ? leadInfo.country : country;
                          leadData.shortNote = leadInfo.notes ? leadInfo.notes : "";
                          leadData.fullname = leadInfo.fullName ? leadInfo.fullName : "";
                          leadData.title = leadInfo.title ? leadInfo.title : "";
                          leadData.email = leadInfo.email ? leadInfo.email : "";
                          leadData.mobile = leadInfo.mobile ? leadInfo.mobile : "";
                          leadData.website = leadInfo.website ? leadInfo.website : "";
                          leadData.facebookUrl = leadInfo.facebook ? leadInfo.facebook : "";
                          leadData.twitterUrl = leadInfo.twitter ? leadInfo.twitter : "";
                          leadData.clientStatus = 1;
                          leadData.permission = leadInfo.permissionType;
                          leadData.is_company_owner = leadInfo.isCompanyOwner;
                          leadData.company_key = leadInfo.companyKey;
                          leadData.customerSince = moment().format('YYYY-MM-DD');

                          if (leadInfo.permissionType == 3) {
                            var userIds = leadInfo.customUsers;
                            var userArr = JSON.parse(userIds);
  
                            var str = userArr
                              .map(ele => {
                                return ele.id;
                              })
                              .join(",");
                            leadData.assignedTo = str;
                          }
  
                          const leadesconvertToUbmClientList : Subscription = this.leadsService
                            .convertToUbmClient(leadInfo.leadsId, leadData)
                            .subscribe((clientRes: any) => {
  
                              if (
                                clientRes.info.code == EStatusCode.OK ||
                                clientRes.info.code == EStatusCode.CREATED
                              ) {
                                var clientInfo = clientRes.data;
  
                                /* Create Invoice for UBM Item's */
                                var itemsList = this.moduleList;
  
                                var discountAmount = 0;
                                if (this.subscriptionValue == 1) {
                                  var numberOfMonths = 1;
                                  discountAmount = 0;
                                } else {
                                  var numberOfMonths = 12;
                                  discountAmount = 10;
                                }
                                var moduleArray = [];
                                this.selectedModuleArr.forEach(
                                  subscribed_module => {
                                    moduleArray.push(subscribed_module.label);
                                  }
                                );
                                var itemData: any = [];
                                var itemOrder = 1;
                                itemsList.forEach((element, index) => {
                                  if (
                                    moduleArray.includes(
                                      element.itemName.toLowerCase()
                                    )
                                  ) {
                                    var newData = {
                                      itemOrder: itemOrder,
                                      itemId: element.savedItemsId,
                                      itemName: element.savedItemsId,
                                      itemDesc: element.itemDesc,
                                      qty: 1,
                                      unitCost: 0.0,
                                      taxName: 0.0,
                                      totalCost: 0.0,
                                      itemTaxTotal: 0.0,
                                      itemTaxRate: 0.0,
                                      price: element.unitCost,
                                      editItem: false,
                                      taxValue: "",
                                      isDeleted: 0,
                                      check: true
                                    };
  
                                    if (
                                      element.pricingType == 0 &&
                                      (element.itemName != "Dashboard" &&
                                        element.itemName != "Calendar" &&
                                        element.itemName != "Client" &&
                                        element.itemName != "Vendor" &&
                                        element.itemName != "Tasks" &&
                                        element.itemName != "HR" &&
                                        element.itemName != "Subscription" &&
                                        element.itemName != "Report" &&
                                        element.itemName != "Utilities")
                                    ) {
                                      
                                      var costForRemainingDays = Number((Number((element.unitCost / daysInCurrentMonth).toFixed(2)) * this.remainingDaysToEndMonth).toFixed(2));
                                      if (element.itemTaxRate) {
                                        var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                        newData.itemTaxRate = element.itemTaxRate;
                                        newData.itemTaxTotal = itemTax;
                                        newData.taxName = element.taxRatesId;
                                      } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                        var itemTax = Number(element.itemTaxTotal)
                                        newData.itemTaxTotal = element.itemTaxTotal;
                                        newData.taxName = element.taxRatesId;
                                      } else {
                                        var itemTax = 0;
                                      }
                                      if (numberOfMonths == 12) {
                                        var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                                        var total_cost = Number(((element.totalCost * numberOfMonths) * this.totalSeats).toFixed(2))
                                      } else {
                                        var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                        var total_cost = Number((currentMonthlyCost * this.totalSeats).toFixed(2))
                                      }
                                      newData.qty = this.totalSeats;
                                      newData.unitCost = currentMonthlyCost;
                                      newData.totalCost = total_cost;
                                    } else if (
                                      element.pricingType == 1 &&
                                      element.itemName != "Dashboard" &&
                                      element.itemName != "Calendar" &&
                                      element.itemName != "Client" &&
                                      element.itemName != "Vendor" &&
                                      element.itemName != "Tasks" &&
                                      element.itemName != "HR" &&
                                      element.itemName != "Subscription" &&
                                      element.itemName != "Report" &&
                                      element.itemName != "Utilities"
                                    ) {
                                      var costForRemainingDays = Number((Number(((element.unitCost / daysInCurrentMonth)).toFixed(2)) * this.remainingDaysToEndMonth).toFixed(2));
                                      if (element.itemTaxRate) {
                                        var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                        newData.itemTaxRate = element.itemTaxRate;
                                        newData.itemTaxTotal = itemTax;
                                        newData.taxName = element.taxRatesId;
                                      } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                        var itemTax = Number(element.itemTaxTotal)
                                        newData.itemTaxTotal = element.itemTaxTotal;
                                        newData.taxName = element.taxRatesId;
                                      } else {
                                        var itemTax = 0;
                                      }
                                      if (numberOfMonths == 12) {
                                        var currentMonthlyCost = Number(((element.unitCost).toFixed(2)));
                                        var total_cost = Number(((element.totalCost * numberOfMonths)).toFixed(2))
                                      } else {
                                        var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                        var total_cost = Number((currentMonthlyCost).toFixed(2))
                                      }
                                      newData.qty = 1;
                                      newData.unitCost = currentMonthlyCost;
                                      newData.totalCost = total_cost;
                                    } else if (
                                      element.itemName == "Dashboard" ||
                                      element.itemName == "Calendar" ||
                                      element.itemName == "Client" ||
                                      element.itemName == "Vendor" ||
                                      element.itemName == "Tasks" ||
                                      element.itemName == "HR" ||
                                      element.itemName == "Subscription" ||
                                      element.itemName == "Report" ||
                                      element.itemName == "Utilities"
                                    ) {
                                      newData.qty = 1;
                                      newData.unitCost = 0;
                                      newData.totalCost = 0;
                                      newData.itemTaxTotal = 0;
                                      // newData.taxName = element.taxRatesId;
                                    } else {
                                      newData.qty = 0;
                                      newData.unitCost = 0;
                                      newData.totalCost = 0;
                                    }
                                    itemData.push(newData);
                                    itemOrder = itemOrder + 1;
                                  }
                                  if (index == (itemsList.length-1)) {
                                    
                                    var costForRemainingDays = Number(Number((Number((this.reservedSeatInfo.unitCost / daysInCurrentMonth).toFixed(2)) *  this.remainingDaysToEndMonth).toFixed(2)).toFixed(2));                                
                                    if (this.reservedSeatInfo.itemTaxRate) {
                                      var itemTax = Number(((costForRemainingDays * this.reservedSeatInfo.itemTaxRate) / 100).toFixed(2));
                                      this.itemTaxRate1 = this.reservedSeatInfo.itemTaxRate;
                                      var itemTaxTotal1 = itemTax;
                                    } else if (!this.reservedSeatInfo.itemTaxRate && this.reservedSeatInfo.itemTaxTotal) {
                                      var itemTax = Number(this.reservedSeatInfo.itemTaxTotal)
                                      this.itemTaxRate1 = 0.00;
                                      var itemTaxTotal1 = itemTax;
                                    } else {
                                      this.itemTaxRate1 = 0.00;
                                      var itemTax = 0;
                                      var itemTaxTotal1 = 0;
                                    }
                                    if (numberOfMonths == 12) {
                                      this.unitCost = this.reservedSeatInfo.unitCost
                                      var currentMonthlyCost = Number((this.reservedSeatInfo.unitCost).toFixed(2));
                                      var costForSeatReservedRow = Number((((this.reservedSeatInfo.totalCost * numberOfMonths)) * this.totalSeats).toFixed(2))
                                    } else {
                                      this.unitCost = costForRemainingDays
                                      var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                      var costForSeatReservedRow = Number((currentMonthlyCost * this.totalSeats).toFixed(2));
                                    }
                                    var newData1 = {
                                      itemOrder: itemOrder,
                                      itemId: this.reservedSeatInfo.savedItemsId,
                                      itemName: this.reservedSeatInfo.savedItemsId,
                                      itemDesc: "",
                                      qty: this.totalSeats,
                                      unitCost: currentMonthlyCost,
                                      taxName: this.reservedSeatInfo.taxRatesId,
                                      totalCost: costForSeatReservedRow,
                                      itemTaxTotal: itemTaxTotal1,
                                      itemTaxRate: this.itemTaxRate1,
                                      editItem: false,
                                      taxValue: "",
                                      isDeleted: 0,
                                      check: true
                                    };
                                    newData1.taxName = this.reservedSeatInfo.taxRatesId
                                    itemData.push(newData1);
                                    itemOrder = itemOrder + 1;
                                  }
                                });
  
                                var invoiceData: any = {
                                  clientId: clientInfo.clientId,
                                  moduleId: 0,
                                  relatedTo: "",
                                  commissionId: 0,
                                  commission: "",
                                  memo: "",
                                  invoiceDate: new Date().toString(),
                                  taxId: Number(this.defaultTaxId),
                                  discount: discountAmount,
                                  notes: this.configService.defaultTerms,
                                  items: itemData,
                                  recuringFrequency: "none",
                                  recurStartDate: "",
                                  recurEndDate: "",
                                  autoPayBy: 0,
                                  autoPayId: 0,
                                  userId: 0,
                                  isSubscriptionInvoice: 1
                                };

                                const upaddSubscriptionInvoices : Subscription = this.invoiceService
                                  .addInvoice(invoiceData)
                                  .subscribe((invRes: any) => {
                                    if (
                                      invRes.info.code == EStatusCode.OK ||
                                      invRes.info.code == EStatusCode.CREATED
                                    ) {
  
                                      /* Update Payment, Transaction, COA, income_expense */
                                      var invoicesId = invRes.data.invoicesId;
                                      var updateData: any = {
                                        invoicesId: invoicesId,
                                        amount: parseFloat(amount.toString()),
                                        clientInfo: clientInfo,
                                        paymentInfo: paymentInfo,
                                        cardInfo: cardInfo
                                      };
                                      
                                      const updaPaymtTrans : Subscription = this.globalService
                                        .updatePaymentTrans(updateData)
                                        .subscribe((PTRes: any) => {
  
                                          if (
                                            PTRes.info.code == EStatusCode.OK ||
                                            PTRes.info.code == EStatusCode.CREATED
                                          ) {
                                            /* Update Subscription start_date end_date */
                                            var today = new Date();
                                            var dd = today.getDate();
                                            var mm = today.getMonth() + 1; //January is 0!
                                            var y = today.getFullYear();
                                            var subscriptionPlan = 0;
                                            var todayD = y + "-" + mm + "-" + dd;
                                            if (this.subscriptionValue == 1) {
                                              var todaysDate = new Date();
                                              var month = mm + 1;
                                              var year = y;
                                              var daysInNextMonth = new Date(year, month, 0).getDate()
                                              var numberOfDaysToAdd = this.remainingDaysToEndMonth;
                                              todaysDate.setDate(
                                                todaysDate.getDate() +
                                                numberOfDaysToAdd
                                              );
                                              var dd = todaysDate.getDate();
                                              var mm = todaysDate.getMonth() + 1;
                                              var y = todaysDate.getFullYear();
                                              var endDate = y + "-" + mm + "-" + dd;
                                              subscriptionPlan = 0;
                                            } else if (
                                              this.subscriptionValue == 2
                                            ) {
                                              var todaysDate = new Date();
                                              var numberOfDaysToAdd = 365 + this.remainingDaysToEndMonth + 1;
                                              todaysDate.setDate(
                                                todaysDate.getDate() +
                                                numberOfDaysToAdd
                                              );
                                              var dd = todaysDate.getDate();
                                              var mm = todaysDate.getMonth() + 1;
                                              var y = todaysDate.getFullYear();
                                              var endDate = y + "-" + mm + "-" + dd;
                                              subscriptionPlan = 1;
                                            }
                                            var subscriptionData = {
                                              subscriptionStartDate: todayD,
                                              subscriptionEndDate: endDate,
                                              subscribedPlan: subscriptionPlan
                                            };
                                            const setupAsubscription : Subscription = this.tenantCompaniesService
                                              .subscription(subscriptionData)
                                              .subscribe((response: any) => {
                                                if (
                                                  response.info.code ==
                                                  EStatusCode.OK ||
                                                  response.info.code ==
                                                  EStatusCode.CREATED
                                                ) {
  
                                                  var data = {
                                                    isComplete: 1,
                                                    reOpen: 0,
                                                    isSuccess: 1,
                                                    otherError: 0,
                                                    otherErrorReason: "",
                                                    isPaymentFailFromTsys: 0
                                                  };
                                                  // this.dialogRef.close(data);
                                                  localStorage.setItem(
                                                    "subscription",
                                                    "1"
                                                  );
                                                  this.toastr.success(
                                                    "Thanks for subscribing"
                                                  );
                                                  if (this.zip == '') {
                                                    this.zip = '0';
                                                  }
                                                  this.configData = {
                                                    industry: this.firstFormGroup.value.industry,
                                                    companyType: this.firstFormGroup.value.companyType,
                                                    taxId: this.firstFormGroup.value.fein,
                                                    leaglName: this.secondFormGroup.value.legalName,
                                                    address: this.secondFormGroup.value.address,
                                                    city: this.city,
                                                    country: this.secondFormGroup.value.country,
                                                    state: this.state,
                                                    zip: this.zip,
                                                    email: this.secondFormGroup.value.email,
                                                    website: this.secondFormGroup.value.website,
                                                    modules: this.checkedListModule.toString()
                                                  };
                                                  const setupA : Subscription = this.setupService.setup(this.configData).subscribe(
                                                    (res: any) => {
                                                      if (res.result.info.code === EStatusCode.OK) {
                                                        localStorage.setItem('setup', '1');
                                                        this.configService.udpateInitialConfigDetails(res.result.data.initialDetail);
                                                        this.configService.getAllConfigDetails();
                                                        
                                                        this.toastr.success("Setup successfull");
                                                        this.configService.updateIsUserLogged.next(true);
                                                        this.router.navigate(["/admin/dashboard"], { replaceUrl: true });
                                                        this.paymentInfoService.hideMessageventListener.next(true);
                                                      } else {
                                                        localStorage.setItem('setup', '0');
                                                        
                                                        this.toastr.error("Something went wrong. Please try again.");
                                                        this.paymentInfoService.hideMessageventListener.next(true);
                                                      }
                                                    }
                                                  );
                                                  this.subscriptions.push(setupA);
                                                } else {
                                                  var sdata = {
                                                    isComplete: 1,
                                                    reOpen: 0,
                                                    isSuccess: 1,
                                                    otherError: 1,
                                                    otherErrorReason:
                                                      response.data.responseMsg,
                                                    isPaymentFailFromTsys: 0
                                                  };
                                                  this.paymentInfoService.hideMessageventListener.next(true);
                                                  this.toastr.error(
                                                    "Subscription failed. Please try again"
                                                  );
                                                }
                                              });
                                              this.subscriptions.push(setupAsubscription);
                                          } else {
                                            var data = {
                                              isComplete: 1,
                                              reOpen: 0,
                                              isSuccess: 1,
                                              otherError: 1,
                                              otherErrorReason:
                                                PTRes.data.responseMsg,
                                              isPaymentFailFromTsys: 0
                                            };
                                            this.paymentInfoService.hideMessageventListener.next(true);
                                            // this.dialogRef.close(data);
                                          }
                                        });
                                        this.subscriptions.push(updaPaymtTrans);
                                    } else {
                                      var data = {
                                        isComplete: 1,
                                        reOpen: 0,
                                        isSuccess: 1,
                                        otherError: 1,
                                        otherErrorReason: invRes.data.responseMsg,
                                        isPaymentFailFromTsys: 0
                                      };
                                      this.paymentInfoService.hideMessageventListener.next(true);
                                      // this.dialogRef.close(data);
                                    }
                                  });
                                  this.subscriptions.push(upaddSubscriptionInvoices);
                              } else {
                                var data = {
                                  isComplete: 1,
                                  reOpen: 0,
                                  isSuccess: 1,
                                  otherError: 1,
                                  otherErrorReason: clientRes.data.responseMsg,
                                  isPaymentFailFromTsys: 0
                                };
                                this.paymentInfoService.hideMessageventListener.next(true);
                                // this.dialogRef.close(data);
                              }
                            });
                            this.subscriptions.push(leadesconvertToUbmClientList); 
                      } else {
                        var data = {
                          isComplete: 1,
                          reOpen: 0,
                          isSuccess: 1,
                          otherError: 1,
                          otherErrorReason: leadRes.data.responseMsg,
                          isPaymentFailFromTsys: 0
                        };
                        this.paymentInfoService.hideMessageventListener.next(true);
                        // this.dialogRef.close(data);
                      }
                    });
                    this.subscriptions.push(getLDetilsByCompanyKey);
                  } else {
                    var data = {
                      isComplete: 1,
                      reOpen: 0,
                      isSuccess: 1,
                      otherError: 1,
                      otherErrorReason: saveCardRes.data.responseMsg,
                      isPaymentFailFromTsys: 0
                    };
                    this.paymentInfoService.hideMessageventListener.next(true);
                    // this.dialogRef.close(data);
                  }
                });
          } else {
            var data = {
              isComplete: 1,
              reOpen: 0,
              isSuccess: 0,
              otherError: 0,
              otherErrorReason: "",
              isPaymentFailFromTsys: 1,
              failedResonseMsg:
                res.data.responseMsg
            };
            this.paymentInfoService.hideMessageventListener.next(true);
            // this.dialogRef.close(data);
          }
        });
    }
    }
    
  }

  submitWizardForFreetrial(){
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.thirdFormGroup.invalid) {
      return;
    } else {
      if (this.zip == '') {
        this.zip = '0';
      }
      
     
      this.configData = {
        industry: this.firstFormGroup.value.industry,
        companyType: this.firstFormGroup.value.companyType,
        taxId: this.firstFormGroup.value.fein,
        leaglName: this.secondFormGroup.value.legalName,
        address: this.secondFormGroup.value.address,
        city: this.city,
        country: this.secondFormGroup.value.country,
        state: this.state,
        zip: this.zip,
        email: this.secondFormGroup.value.email,
        website: this.secondFormGroup.value.website,
        modules: this.checkedListModule.toString()
      };
      const usersetupAuth : Subscription = this.setupService.setup(this.configData).subscribe(
        (res: any) => {
          if (res.result.info.code === EStatusCode.OK) {
            localStorage.setItem('setup', '1');
            this.configService.udpateInitialConfigDetails(res.result.data.initialDetail);
            this.configService.getAllConfigDetails();
            this.toastr.success("Setup successfull");
            this.configService.updateIsUserLogged.next(true);
            this.router.navigate(["/admin/dashboard"], { replaceUrl: true });
          } else {
            localStorage.setItem('setup', '0');
            
            this.toastr.error("Something went wrong. Please try again.");
          }
        }
      );
      this.subscriptions.push(usersetupAuth);
    }
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    let x = this.getComponentByType(address, "street_number");
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.city = '';
    this.state = '';
    this.zip = '';
    this.address = address.name;
    this.addressLine1 = ''

    address.address_components.forEach(element => {
      if (element.types[0] == 'country') {
        this.country = element.long_name;
      } else if (element.types[0] == 'locality') {
        this.city = element.long_name;
      } else if (element.types[0] == 'administrative_area_level_1') {
        this.state = element.long_name;
      } else if (element.types[0] == 'postal_code') {
        this.zip = element.long_name;
      } else {
        if (this.address == '') {
          this.address = element.long_name;
        } else {
          this.address = this.address;// + ", " + element.long_name;
        }
      }
    });
    if (this.city) {
      this.addressLine1 = this.city;
      if (this.state) {
        this.addressLine1 = this.addressLine1 + ", " + this.state;
        if (this.zip) {
          this.addressLine1 = this.addressLine1 + ", " + this.zip;
        }
      }
    } else if (this.state) {
      this.addressLine1 = this.state;
      if (this.zip) {
        this.addressLine1 = this.addressLine1 + ", " + this.zip;
      }
    } else if (this.zip) {
      this.addressLine1 = this.zip;
    }
    this.secondFormGroup.patchValue({
      country: this.country,
      cityStateZip: this.addressLine1,
      address: this.address
    });
    let addressdata;
    addressdata = this.secondFormGroup.value.cityStateZip.split(',');

    if (addressdata[0]) {
      this.showError = false;
      this.city = addressdata[0];
    }
    else {
      this.showError = true;
      this.errorMsg = 'Please add the city';
    }
    if (addressdata[1]) {
      this.showError1 = false;
      this.state = addressdata[1];
    }
    else {
      this.showError1 = true;
      this.errorMsg1 = 'Please add the state';
    }
    if (addressdata[2]) {
      this.showError = false;
      this.zip = addressdata[2];
    }
    else {
      this.showError = true;
      this.errorMsg = 'Please add the zip code';
    }
  }

  checkbillingcityStateZip() {
    if (this.secondFormGroup.value.cityStateZip) {
      let addressdata;
      addressdata = this.secondFormGroup.value.cityStateZip.split(',');
      if (addressdata[0]) {
        this.showError = false;
        this.city = addressdata[0];
      } else {
        this.showError = true;
        this.errorMsg = 'Please add the city';
      }
      if (addressdata[1]) {
        this.showError1 = false;
        this.state = addressdata[1];
      } else {
        this.showError1 = true;
        this.errorMsg1 = 'Please add the state';
      }
      if (addressdata[2]) {
        this.showError = false;
        this.zip = addressdata[2];
      } else {
        this.showError = true;
        this.errorMsg = 'Please add the zip code';
      }
    } else {
      this.showError = false;
      this.showError1 = false;
    }
  }

  public getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  logout() {
    const leadeslogoutList : Subscription = this.loginService.logout().subscribe((res) => {
      this.socketService.disconnectSocket();
      localStorage.clear();
      this.router.navigate(['/login'], { replaceUrl: true });
      this.titleService.setTitle('CodeNgine');
    });
    this.subscriptions.push(leadeslogoutList);
  }

  checkEmailExists(value){
    if (value) {
      const contEmailExists : Subscription = this.leadsService.contactEmailExists(value).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          this.check = true;
        } else {
          this.check = false;
        }
      });
      this.subscriptions.push(contEmailExists);
      } else {
        this.check = true;
      }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  trackByIndex(index: number, list: any): number {
    return index
  }

  SearchData(zipcode){
    this.billingZipcode = zipcode;
  }
}
