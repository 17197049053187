import { AllJobPostComponent } from './admin/hr/job-circular/job-posted/all-job-post/all-job-post.component';
import { JobPostViewComponent } from './admin/hr/job-circular/job-posted/job-post-view/job-post-view.component';
import { AppRmaViewComponent } from './admin/support/rma/app-rma-view/app-rma-view.component';
import { Routes } from '@angular/router';
import { InvoiceViewComponent } from './admin/accounting/invoice/invoice-view/invoice-view.component';
import { EstimateViewComponent } from './admin/sales/estimate/estimate-view/estimate-view.component';
import {
    PurchaseOrderViewComponent
} from './admin/accounting/purchase-order/purchase-order-view/purchase-order-view.component';
import {
    AdminClientAgreementViewComponent
} from './admin/admin-client/admin-client-detail/admin-client-agreement/admin-client-agreement-view/admin-client-agreement-view.component';
import { BugsViewComponent } from './admin/support/bugs/bugs-detail/bugs-view/bugs-view.component';
import { ThankYouComponent } from './admin/survey/surveys-thank-you/thank-you.component';
import { StartCampaignComponent } from './admin/survey/start_campaign/start-campaign.component';
import { DocsComponent } from './docs/docs.component';
import { AdminDocsignComponent } from './admin/shared/bugs-attachment/admin-docsign/admin-docsign.component';
import { ClientDocsignComponent } from './admin/shared/bugs-attachment/client-docsign/client-docsign.component';

import adminRoutes from './admin/admin.routes';
import supportRoutes from './admin/support/support.routes';
import coreRoutes from './core/core.routes';
import docsRoutes from './docs/docs.routing.module';
import clientRoutes from './client/client.routes';
import tasksRoutes from './admin/task/task-routing.module';


export const AppRoutes: Routes = [
    ...adminRoutes,
    ...supportRoutes,
    ...coreRoutes,
    ...docsRoutes,
    ...clientRoutes,
    ...tasksRoutes,

    {
        path: 'invoice/:id',
        component: InvoiceViewComponent
    },
    {
        path: 'rma/:id',
        component: AppRmaViewComponent
    },
    {
        path: 'careers',
        component: AllJobPostComponent
    },
    {
        path: 'careers/:id/:salt/:secret',
        component: JobPostViewComponent
    },
    {
        path: 'estimate/:id',
        component: EstimateViewComponent
    },
    {
        path: 'purchaseOrder/:id',
        component: PurchaseOrderViewComponent,
    },
    {
        path: 'agreement/:id',
        component: AdminClientAgreementViewComponent
    },
    {
        path: 'support/bug/:id',
        component: BugsViewComponent
    },
    {
        path: 'start_campaign/:id',
        component: StartCampaignComponent
    },
    {
        path: 'start_campaign/:viewType/:id',
        component: StartCampaignComponent
    },
    {
        path: 'thank_you/:id',
        component: ThankYouComponent
    },
    // {
    //   path: 'client',
    //   loadChildren: './client/client.module#ClientModule'
    // },
    {
        path: 'admin/docs',
        component: DocsComponent
    },
    // {
    //   path:'admin/docs/platform-docs',
    //   component:PlatformDocsInfoComponent
    // }

    // CodeNgine Docsign view page
    {
        path: 'admin/utiliko_docsign/:id',
        component: AdminDocsignComponent
    },
    {
        path: 'client/utiliko_docsign/:id',
        component: ClientDocsignComponent
    },

    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'admin/dashboard'
    },
    {
        path: '**',
        redirectTo: 'admin/dashboard'
    }
];
