import { OverviewComponent } from './knowledgebase/overview/overview.component';
import { ArticleDetailsComponent } from './knowledgebase/article-details/article-details.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WizardComponent } from './wizard/wizard.component';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsComponent } from './forms/forms.component';
import { ReleaseNotesGlobalComponent } from './release-notes-global/release-notes-global.component';
import { ReviewSubmitComponent } from './review-submit/review-submit.component';
import { ResetPasswordLinkComponent } from './reset-password-link/reset-password-link.component';
import { ProposalsSignatureComponent } from './proposals-signature/proposals-signature.component';

const coreRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    // {
    //     path: 'register',
    //     component: RegisterComponent
    // },
    {
        path: 'reset-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password-link',
        component: ResetPasswordLinkComponent
    },
    {
        path: 'wizard',
        component: WizardComponent
    },
    {
        path: 'subscription',
        component: SubscriptionComponent
    },
    {
        path: 'thank-you',
        component: ThankYouComponent
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent
    },
    {
        path: 'form/:id/:salt/:secret',
        component: FormsComponent
    },
    // {
    //     path: 'knowledgebase',
    //     component: OverviewComponent
    // },
    // {
    //     path: 'knowledgebase/:categoryName/:articleName',
    //     component: ArticleDetailsComponent
    // },
    // {
    //     path: 'release-notes',
    //     component: ReleaseNotesGlobalComponent
    // },
    {
        path: 'rating/:module/:id',
        component: ReviewSubmitComponent
    },
    {
        path: 'career',
        loadChildren: () => import('./career/career.routes')
    },
    // {
    //     path: 'signup',
    //     loadChildren: () => import('./pbx/pbx.routes')
    // },
    {
        path: 'proposal/:id',
        component: ProposalsSignatureComponent
    },
];

export default coreRoutes;
