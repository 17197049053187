<mat-toolbar class="themeColor" [ngClass]="themeData" >
  <img src="{{bugDetail?.companyLogo}}"  width="90px;" style="margin-left: 86px; height: inherit;"
       onError="this.src='assets/img/default_company_logo.png'">
  <span *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor" [ngClass]="themeData"  style="color: black">{{bugDetail?.companyName}}</span>
  <span *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor" [ngClass]="themeData"  style="color: white">{{bugDetail?.companyName}}</span>
</mat-toolbar>
<section class="inv-main-content">
  <div id="inv-detail-content">

<div class="container">
  <div class="col-md-12 p-l-10 p-r-10 p-t-10"><h3 class="align">BUG DETAIL</h3>
  <div class="panel panel-default">
      <div class="panel-heading">
      <div class="row">
        <div class="col-md-6 detail-view-title">
          <span class="detail-view-title-name">{{bugDetail.bugTitle}}</span> <span class="detail-view-title-default"> - Details</span>
        </div>
  </div>
  </div>
  </div>
  <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
        <div class="col-md-12">
          <span>Bug Information</span>
        </div>
      </div>
      </div>
      <div class="panel-body">

  <div class="row">
      <div class="col-md-6">
        <p class="small-header">Bug Title </p>
        <p class="small-content">{{bugDetail.bugTitle}}</p>
      </div>
      <div class="col-md-6">
        <p class="small-header">Bug Status</p>
          <p *ngIf="bugDetail.bugStatus == 'unconfirmed'" class="tag-priority-btn secondaryButton display-inline-block">Unconfirmed</p>
          <p *ngIf="bugDetail.bugStatus == 'confirmed'" class="tag-priority-btn warningButton display-inline-block status">Confirmed</p>
          <p *ngIf="bugDetail.bugStatus == 'in_progress'" class="tag-priority-btn primaryButton display-inline-block status">In Progress</p>
          <p *ngIf="bugDetail.bugStatus == 'resolved'" class="tag-priority-btn voiletButton display-inline-block status">Resolved</p>
          <p *ngIf="bugDetail.bugStatus == 'verified'" class="tag-priority-btn successButton display-inline-block status">Verified</p>
      </div>
      <div class="col-md-6">
        <div *ngIf="bugDetail.projectId">
          <p class="small-header">Project Name</p>
          <p class="small-content">{{bugDetail.projectName}}</p>
        </div>
        <div *ngIf="bugDetail.clientId">
          <p class="small-header">Client Name</p>
          <p class="small-content">
          {{bugDetail.clientName}}</p>
        </div>
        <div *ngIf="bugDetail.opportunitiesId">
          <p class="small-header">Opportunity Name</p>
          <p class="small-content">{{bugDetail.opportunityName}}</p>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-12">
            <span>Other Details</span>
          </div>
        </div>
      </div>
      <div class="panel-body">
          <div class="row">
    <div class="col-md-6">
      <p class="small-header">Reporter </p>
      <p class="small-content">{{bugDetail.reporterName}}</p>
    </div>
    <div class="col-md-6">
      <p class="small-header">Priority</p>
      <div *ngIf="bugDetail.priority === 'Low'">
        <p class="text-success" style="width:50px">{{bugDetail.priority}}</p>
      </div>
      <div *ngIf="bugDetail.priority === 'Medium'">
        <p class="text-warning" style="width:50px">{{bugDetail.priority}}</p>
      </div>
      <div *ngIf="bugDetail.priority === 'High'">
        <p class="text-danger" style="width:50px">{{bugDetail.priority}}</p>
      </div>
      <div *ngIf="bugDetail.priority === 'Critical'">
        <p class="text-primary" style="width:50px">{{bugDetail.priority}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <p class="small-header">Update On</p>
      <p *ngIf="bugDetail.onUpdate" class="small-content">{{bugDetail.onUpdate | commonDate : 'date_time'}}</p>
    </div>
    <div class="col-md-6">
      <p class="small-header">Created Date</p>
      <p *ngIf="bugDetail.onCreate" class="small-content">{{bugDetail.onCreate | commonDate : 'date_time'}}</p>
    </div>
  </div>
  </div>
  </div>
  <div class="panel panel-default">
        <div class="panel-heading">
          <div class="row">
            <div class="col-md-12">
              <span>Participants</span>
            </div>
          </div>
        </div>
        <div class="panel-body">
            <div class="row" style="padding-left:10px;">
    <div class="col-md-12">
      <span class="everyone_participants" *ngIf="bugDetail?.permissionType === 2">Everyone<i class="fas fa-exclamation" matTooltip="Permission to all department Users"></i>
            </span>
        <div class="row userImg" *ngFor="let userDetails of participantsInfo">
              <span *ngIf="userDetails.activated == 1">

                <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
                     class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'"> <!--[src]="userDetails.avatar" -->
                <span style="margin: 0 0 8px -10px;" class="circle circle-success circle-lg"></span>
              </span>
          <span *ngIf="userDetails.activated == 0">

                <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
                     class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'"> <!--[src]="userDetails.avatar" -->
                <span style="margin: 0 0 8px -10px;" class="circle circle-warning circle-lg"></span>
              </span>
        </div>
    </div>
  </div>
  </div>
  </div>
  </div>
</div>
  </div>
</section>
<footer class="page-footer">
<div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co" target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
    <p class="alignright">{{version}}</p>
  </div>
</footer>
<app-spinner></app-spinner>



