import { TenantExistsService } from '../../service/tenant-exists.service';
import {
    Component,
    OnInit,
    OnDestroy,
    inject
} from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from '../../service/login.service';
import { COMMON, StorageKey } from '../../service/constant';
import { ConfigService } from '../../service/config.service';
import CryptoJS from 'crypto-js';
import { Title } from '@angular/platform-browser';
import { ReleaseNoteService } from '../../service/release-note.service';
import { AlertCommonDialogData, EStatusCode } from '../../service/Utils/Interfaces.class';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNoteNotificationComponent } from '../release-note-notification/release-note-notification.component';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SocketService } from '../../service/socket.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { ReCaptchaComponent } from './re-captcha/re-captcha.component';
import { MatIconButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix, MatError, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatIcon,
        MatPrefix,
        NgIf,
        MatError,
        MatIconButton,
        MatSuffix,
        ReCaptchaComponent,
        MatCheckbox,
        RouterLink,
        SpinnerComponent
    ]
})
export class LoginComponent implements OnInit, OnDestroy {
    readonly #toastr: ToastrService = inject(ToastrService);

    private readonly subscriptions: Subscription[] = [];
    private loginAttempt = 0;

    public data: any;
    public loginForm: FormGroup;
    public submitted = false;

    public hostname: string;
    public version: string;

    public remeberMeCheked = false;
    public rememberMeFlag = false;
    public disableLoginButton = false;
    public password: string;
    public username: string;

    public registerPageURL: string;
    public hideDevice = true;
    public showCaptcha = false;

    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private tenantExistsService: TenantExistsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private configService: ConfigService,
        private titleService: Title,
        private releaseNoteService: ReleaseNoteService,
        private socketService: SocketService,
        private dialog: MatDialog
    ) {

        this.titleService.setTitle('CodeNgine');

        const x = document.cookie.split(';');
        for (let i = 0; i < x.length; i++) {
            const cookiePair = x[i].split('=');
            if ('urmckUser' === cookiePair[0].trim()) {
                this.username = cookiePair[1];
                this.rememberMeFlag = true;
            }
            if ('urmckPass' === cookiePair[0].trim()) {
                this.rememberMeFlag = true;
            }
        }
        this.version = COMMON.VERSION;
        this.hostname = window.location.hostname;

        this.registerPageURL = environment.REGISTER_PAGE_URL;
        if (localStorage.getItem(StorageKey.ACCESSTOKEN)) {
            this.configService.updateIsUserLogged.next(true);
            this.router.navigate(['/'], { replaceUrl: true });
        } else {
            if (!localStorage.getItem('logged')) {
                localStorage.setItem('logged', 'logged');
                this.releaseNoteNotification();
            }
            this.configService.updateIsUserLogged.next(false);
        }
    }
    ngOnInit() {

        if (this.hostname !== 'localhost') {
            this.checkDomain(this.hostname);
        }
        document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
        const isQaEnvironment = window.location.host.includes('milagro.nobackend.io') || window.location.host.includes('localhost');

        this.loginForm = this.fb.group({
            email: ['', [Validators.required, ...(isQaEnvironment ? [] : [Validators.pattern(COMMON.EMAIL_PATTERN)])]],
            password: ['', [Validators.required]],
            rememberMe: ['']
        });

        if (this.username) {
            this.loginForm.patchValue({
                email: this.username,
                password: this.password,
            });
        }
        this.activatedRoute.queryParams.subscribe((params: Params) => { });
    }
    async checkDomain(domain: string) {
        if (domain) {
            domain = domain.replace(/ /g, '').toLowerCase().trim();

            const isDomainExists: any = await this.tenantExistsService.domainExists(domain)
                .pipe(take(1))
                .toPromise();
            if (isDomainExists && isDomainExists.data && isDomainExists.data.isDomainExist) {
                this.loginService.setTenantCompanyKey(isDomainExists.data.tenantCompanyKey);
            } else {
                this.router.navigate(['/page-not-found'], { replaceUrl: true });
            }
        }
    }

    get f() {
        return this.loginForm.controls;
    }
    public onSubmit(): any {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return this.loginForm.controls;
        }

        if (this.loginForm.value.rememberMe) {
            const d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString();
            document.cookie = 'urmckUser' + '=' + this.loginForm.value.email + ';' + expires + ';path=/';
            const pass = CryptoJS.AES.encrypt(JSON.stringify(this.loginForm.value.password), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
            document.cookie = 'urmckPass' + '=' + pass + ';' + expires + ';path=/';
        } else {
            document.cookie = 'urmckUser' + '=;path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'urmckPass' + '=;path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        this.data = {
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
            domain: this.hostname,
            loginDevice: 0,
        };
        const userLoginsrv: Subscription = this.loginService.userLogin(this.data).subscribe(
            (res: any) => {

                if (res.statusCode === EStatusCode.OK) {
                    const pass = CryptoJS.AES.encrypt(JSON.stringify(this.loginForm.value.password), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
                    const tk = CryptoJS.AES.encrypt(JSON.stringify(res.tkey), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
                    this.loginService.setShareValue(this.loginForm.value.password);
                    localStorage.setItem(StorageKey.ACCESSTOKEN, res.accessToken);
                    localStorage.setItem('isTrialOver', res.isTrialOver);
                    localStorage.setItem('userId', res.userId);
                    localStorage.setItem('clientId', res.clientId);
                    localStorage.setItem('fullname', res.fullname);
                    localStorage.setItem('theme', res.theme);
                    localStorage.setItem('owner', res.isOwner);
                    localStorage.setItem('setup', res.issetupdone);
                    localStorage.setItem('isUbm', res.isUBM);
                    localStorage.setItem('domain', res.domain);
                    localStorage.setItem('isSubscriptionCancelled', res.isSubscriptionCancelled);
                    localStorage.setItem('tk', tk);
                    localStorage.setItem('passkey', pass);
                    localStorage.setItem('email', res.initialDetail.userDetails.email);

                    this.configService.udpateInitialConfigDetails(res.initialDetail);
                    this.configService.getAllConfigDetails();
                    this.socketService.getSocketConnect(res.accessToken);

                    if (res.issetupdone === 1 && res.isSubscriptionOver === 0 && res.initialDetail.userDetails.userType !== 2 && res.initialDetail.userDetails.userType !== 4) {
                        this.#toastr.success('Login successful');
                        this.#toastr.error('Do not forget to clock in and out');
                        localStorage.setItem('subscription', '1');
                        this.configService.updateIsUserLogged.next(true);
                        this.router.navigate(['/admin/dashboard']);
                        this.titleService.setTitle('Dashboard');
                    } else if (res.issetupdone === 1 && res.isSubscriptionOver === 0 && res.initialDetail.userDetails.userType === 2) {
                        this.#toastr.success('Login successful');
                        localStorage.setItem('subscription', '1');
                        this.configService.updateIsUserLogged.next(true);
                        this.router.navigate(['/client/dashboard']);
                        this.titleService.setTitle('Dashboard');
                    } else if (res.issetupdone === 1 && res.isSubscriptionOver === 1 && res.isSubscriptionCancelled === 1) {
                        this.#toastr.error('Subscription has been cancelled for this domain. Please contact administrator.');
                        localStorage.clear();
                    } else if (res.issetupdone === 1 && res.isSubscriptionOver === 1 && res.isSubscriptionCancelled === 0) {
                        localStorage.setItem('subscription', '0');
                        this.#toastr.warning('Please renew subscription.');
                        this.router.navigate(['/subscription'], { replaceUrl: true });
                        this.titleService.setTitle('Subscription');
                    } else {
                        this.#toastr.success('Login successful');
                        localStorage.setItem('subscription', '1');
                        this.#toastr.success('Complete setup to get started.');
                        this.router.navigate(['/wizard'], { replaceUrl: true });
                        this.titleService.setTitle('Wizard');
                    }
                } else if (res.statusCode === EStatusCode.OK && res.domain !== this.hostname) {
                    this.#toastr.error(
                        'Login credentials do not match with ' + res.domain + ' access'
                    );
                    // this.router.navigate(["/page-not-found"], { replaceUrl: true });
                } else {
                    this.#toastr.error('Login credentials do not match the system. Please try again');
                }
            },
            error => {
                if (
                    error.error &&
                    error.error.error &&
                    error.error.error.data &&
                    error.error.error.data.responseMsg
                ) {
                    if (error.error.error.data.isActive === 0) {
                        this.loginAttempt++;
                        this.loginAttempt > 5 ? this.#toastr.error('The password you entered is not correct. Please wait for minute to enable the reCAPTCHA again.') : this.#toastr.error(error.error.error.data.responseMsg);
                        this.showCaptcha = this.disableLoginButton = this.loginAttempt >= 5;
                    } else {
                        this.#toastr.error(
                            error.error.error.data.responseMsg
                        );
                    }
                } else if (error.info.data.isDomainExists === 0) {
                    this.#toastr.error(
                        'Domain does not exists'
                    );
                } else {
                    this.#toastr.error(
                        'Login credentials do not match the system. Please try again'
                    );
                }
            }
        );
        this.subscriptions.push(userLoginsrv);
    }
    checkSelected() {
        if (this.loginForm.value.rememberMe) {
            this.remeberMeCheked = true;
            this.rememberMeFlag = true;
        } else {
            this.remeberMeCheked = false;
            this.rememberMeFlag = false;
        }
    }

    public disableLoginBtn(param: boolean) {
        this.disableLoginButton = param;
    }

    async releaseNoteNotification() {
        const getNotif: any = await this.releaseNoteService.getNotification(this.hostname, 'login')
            .pipe(take(1))
            .toPromise();
        if (getNotif.info.code === EStatusCode.OK) {
            if (getNotif.data.dateValue.length) {

                const alertCommonDialogData: AlertCommonDialogData = {
                    title: '',
                    message: '',
                    submitButtonText: '',
                    cancelButtonText: ''
                };
                alertCommonDialogData.title = getNotif.data.title;
                alertCommonDialogData.message = getNotif.data.content;
                alertCommonDialogData.submitButtonText = 'OK';
                alertCommonDialogData.cancelButtonText = 'login';

                this.dialog.open(ReleaseNoteNotificationComponent, {
                    width: COMMON.CLONE_INVOICE_WIDTH,
                    height: COMMON.CLONE_INVOICE_HEIGHT,
                    data: alertCommonDialogData
                });
                localStorage.clear();
            }
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            if (subscription) {
                subscription.unsubscribe();
            }
        });
    }

}
