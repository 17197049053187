import { LoginService } from './service/login.service';
import { TenantExistsService } from './service/tenant-exists.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StorageKey } from './service/constant';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfigService } from './service/config.service';
import { TranslateService } from '@ngx-translate/core';
import CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { TenantCompaniesService } from './service/tenant-companies.service';
import { EStatusCode } from './service/Utils/Interfaces.class';
import { SessionPopupDialogService } from './service/session-popup-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    public href = '';
    public isUserLoggedIn = false;
    public themeClass = '';
    private readonly subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private tenantExistsService: TenantExistsService,
        private activatedRoute: ActivatedRoute,
        private configService: ConfigService,
        private loginService: LoginService,
        private tenantCompaniesService: TenantCompaniesService,
        private translate: TranslateService,
        private sessionPopupDialogService: SessionPopupDialogService,
        private toastr: ToastrService
    ) {
        translate.setDefaultLang('en');
        this.isUserLoggedIn = this.configService.isUserLogged;
        this.themeClass = this.configService.userTheme;
        this.configService.updateIsUserLogged.subscribe((isUserLogged: boolean) => {
            this.isUserLoggedIn = isUserLogged;
            this.themeClass = this.configService.userTheme;
        });
        this.isUserLoggedIn = this.configService.isUserLogged;

    }

    ngOnInit() {
        const accesstoken = localStorage.getItem(StorageKey.ACCESSTOKEN);
        if (accesstoken) {
            this.configService.getAllConfigDetails();
        } else {
            this.configService.clearValues();
        }

        this.href = this.router.url;
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (Object.keys(params).length > 0) {
                const user = params.user;
                const activationKey = params.activationKey;
                let email, domain;
                let password, ck;
                const status = params.status;
                const reactivationKey = params.reactivationKey;
                if (params.switchCompany) {
                    const obj = decodeURIComponent(params.switchCompany);
                    const decryptedData = JSON.parse(CryptoJS.AES.decrypt(obj.toString(), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8));
                    email = decryptedData.email;
                    password = CryptoJS.AES.decrypt(decryptedData.password, '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8);
                    password = password.substring(1, password.length - 1);
                    ck = CryptoJS.AES.decrypt(decryptedData.ck, '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8);
                    ck = ck.substring(1, ck.length - 1);
                    domain = decryptedData.domain;
                }
                if (!accesstoken && user && activationKey) {
                    const activeSubscription: Subscription =
                        this.tenantExistsService.activateTenant(user, activationKey).subscribe(
                            (res: any) => {
                                if (res.info.code === EStatusCode.OK) {
                                    this.toastr.success(res.data.responseMsg);
                                } else {
                                    this.toastr.error(res.data.responseMsg);
                                }
                            }
                        );
                    this.subscriptions.push(activeSubscription);

                } else if (params.enc && params.userId) {
                    localStorage.setItem('accessToken', params.enc);
                    localStorage.setItem('userId', params.userId);
                    localStorage.setItem('setup', '1');
                    window.location.replace('/admin/dashboard');
                } else if (status && reactivationKey) {
                    const hostname = window.location.host;

                    const cancelSubs: Subscription = this.tenantCompaniesService.reactivateSubscription({
                        hostname,
                        status
                    }).subscribe((response: any) => {
                        if (response.info.code === EStatusCode.OK) {
                            let respMsg;
                            if (accesstoken) {
                                respMsg = 'Hurray! you have successfully re-activated your account.';
                            } else {
                                respMsg = 'Hurray! you have successfully re-activated your account. Login to continue using CodeNgine.';
                            }
                            this.toastr.success(respMsg);
                        } else {
                            this.toastr.error('Failed to re-activate subscription. Contact our support team.');
                        }
                    });
                    this.subscriptions.push(cancelSubs);
                } else if (!accesstoken && email && password && domain) {
                    const data = {
                        email: email,
                        password: password,
                        domain: domain,
                        tenantCompanyKey: ck,
                        loginDevice: 0,
                    };
                    const loginSubscription: Subscription =
                        this.loginService.userLogin(data).subscribe(
                            (res: any) => {

                                if (res.statusCode === EStatusCode.OK && res.domain === domain) {
                                    localStorage.setItem(StorageKey.ACCESSTOKEN, res.accessToken);
                                    localStorage.setItem('isTrialOver', res.isTrialOver);
                                    localStorage.setItem('userId', res.userId);
                                    localStorage.setItem('clientId', res.clientId);
                                    localStorage.setItem('theme', res.theme);
                                    localStorage.setItem('owner', res.isOwner);
                                    localStorage.setItem('setup', res.issetupdone);
                                    localStorage.setItem('isUbm', res.isUBM);
                                    localStorage.setItem('domain', res.domain);
                                    localStorage.setItem('tk', res.tkey);
                                    localStorage.setItem('layoutFixed', res.layoutFixed);
                                    localStorage.setItem('layoutBoxed', res.layoutBoxed);
                                    localStorage.setItem('asideFloat', res.asideFloat);
                                    localStorage.setItem('passkey', password);
                                    this.configService.updateIsUserLogged.next(true);
                                    this.router.navigate(['/'], { replaceUrl: true });
                                    this.configService.udpateInitialConfigDetails(res.initialDetail);
                                    if (res.issetupdone === 1 && res.isSubscriptionOver === 0) {
                                        this.toastr.success('Login successful');
                                        localStorage.setItem('subscription', '1');
                                    } else if (res.issetupdone === 1 && res.isSubscriptionOver === 1) {
                                        localStorage.setItem('subscription', '0');

                                        this.toastr.warning('Please renew subscription.');
                                        this.router.navigate(['/subscription'], { replaceUrl: true });
                                    } else {
                                        this.toastr.success('Login successful');
                                        localStorage.setItem('subscription', '1');

                                        this.toastr.success('Complete setup to get started.');
                                        this.router.navigate(['/wizard'], { replaceUrl: true });
                                    }
                                } else {
                                    this.toastr.error('Login credentials do not match the system. Please try again');
                                    this.router.navigate(['/page-not-found'], { replaceUrl: true });
                                }
                            },
                            error => {

                                if (error.error && error.error.error && error.error.error.data && error.error.error.data.responseMsg) {
                                    this.toastr.error('Login credentials do not match the system. Please try again');
                                } else {
                                    this.toastr.error('Login credentials do not match the system. Please try again');
                                }
                            }
                        );
                    this.subscriptions.push(loginSubscription);
                    // this.router.navigate(['login']);
                }
            } else {
                if (!accesstoken) {// && this.href != '/') {
                    // this.router.navigate(['login']);
                } else {

                    // check setupDone or not before navigating dashboard page
                    if (localStorage.getItem('setup') === '0') {
                        localStorage.setItem('subscription', '1');

                        this.toastr.success('Complete setup to get started.');
                        this.router.navigate(['/wizard'], { replaceUrl: true });
                    }
                }
            }
        });

        const sessionChannel = new BroadcastChannel('session-channel');
        // event listener to handle emitted events from the session-channel
        sessionChannel.onmessage = (event) => {
            if (event.data === 'tokenExpired') {
                this.sessionPopupDialogService.closeDialog();
            }
        };

    }

    ngOnDestroy() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }
}
