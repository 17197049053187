import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import {OpportunitiesService} from "../../../service/opportunities.service";
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-helper-model',
    templateUrl: './helper-model.component.html',
    styleUrls: ['./helper-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class HelperModelComponent implements OnInit {

  htmlStr = '{"TYPE" : "GOOGLE_CLOUD_STORAGE","GCSDETAIL":{"PROJECTID" : "CodeNgine","AUTHFILENAME" : "utiliko-f12bcfba14c0.json","BUCKETNAME" : "utiliko-test", "EXPIRYTIME": 3110400000000,"ACTION" : "read"}}';
     
  htmlStr1 = ' [{“id”:20}] and (20, 100) -> [{“id”:20},{“id”:100}]';

  htmlStr2 = '{nin:[3, 2]}';

  htmlStr3 = "{emailed: 'Yes', dateSent: new Date()}";

  htmlStr4 = "{ cronTime: '0 * 1 * * *', onTick: recurInvCr, }";

  public path:any; 
  constructor(public opportunitiesService: OpportunitiesService) { }

  ngOnInit() {
    this.path = environment.BASE_URL;
  }

}
