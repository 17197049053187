import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Language } from '../../../../service/language';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../service/login.service';
import { EStatusCode, IResponse, ThemeColorInterface } from '../../../../service/Utils/Interfaces.class';
import { SOCKET_KEY, ThemeColor } from '../../../../service/constant';
import { IUserProfile, SettingsService } from '../../../../service/settings.service';
import { Users } from '../../../../service/models/Users';
import { AppComponentBase } from '../../../shared/AppComponentBase';
import CryptoJS from 'crypto-js';
import { Notification } from '../../../../service/models/Notification';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EmployeeService } from '../../../../service/employee.service';
import { OpportunitiesService } from '../../../../service/opportunities.service';
import { GlobalService } from '../../../../service/global.service';
import { DashboardService } from '../../../../service/dashboard.service';
import { AgreementService } from '../../../../service/agreement.service';
import { TourServiceService } from '../../../../service/tour-service.service';
import { NewMailboxService } from '../../../mailbox/mailbox-service/Mailbox-new.service';
import { MatDialog } from '@angular/material/dialog';
import { PresenceMonitoringComponent } from '../../Presence-monitoring/Presence-monitoring.component';
import { CurrentUserService } from '../../../softphone/shared/services/current-user.service';
import { SocketService } from '../../../../service/socket.service';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatBadge } from '@angular/material/badge';
import { MatLine } from '@angular/material/core';
import { MatListItem } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { OverlayTourComponent } from '../overlay-tour/overlay-tour.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { GlobalSearchComponent } from '../global-search/global-search.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
  imports: [NgIf, GlobalSearchComponent, NgClass, ExtendedModule, MatIconButton, MatTooltip, MatMenuTrigger, MatIcon, OverlayTourComponent, MatMenu, RouterLink, MatListItem, MatLine, MatBadge, MatTabGroup, MatTab, InfiniteScrollDirective, NgFor, MatButton, FormsModule, ReactiveFormsModule, NgSelectModule, MatMenuItem, CommonDatePipe, MatSuffix]
})
export class AppHeaderComponent extends AppComponentBase implements OnInit, OnDestroy {

  public changeLangComp: FormGroup;
  languages = [];
  themeColor: ThemeColorInterface[];
  userId = localStorage.getItem('userId');
  themeClass = this.configService.userTheme;
  notification = this.configService.notification;
  layoutFix = this.configService.layoutFixed;
  layoutBox = this.configService.layoutBoxed;
  asideFloat = this.configService.asideFloat;
  tenantKey: any;
  themeData = {};
  client;
  companyList: any;
  hostname: any;
  newData: any = [];
  parent: any = [];

  password: string;
  public userName: string;
  hasMultipleCompanies = false;
  public data: any;
  private subscription: Subscription;
  private notificationSubscription: Subscription;
  public userProfileImg: any;
  public userData: Users;
  private userProfileData: IUserProfile = {
    fullname: '',
    avatar: ''
  };
  showPreviousLogin = false;

  // notification variable
  public limitStart: number;
  public notifyList: any = [];
  public readNotifyList: any = [];
  public unReadNotifyList: any = [];

  public notifyScrollList: any = [];
  public unReadScrollList: any = [];
  public readNotifyScrollList: any = [];

  public unReadCount = 0;
  public readCount = 0;
  public allCount = 0;

  private allLimitStart = 0;
  private readLimitStart = 0;
  private unReadLimitStart = 0;
  public loginType: number;
  langControl = new FormControl();
  compControl = new FormControl();
  filteredLang: Observable<string[]>;

  activeTourIndex;
  public text;
  public icon;
  public label;
  private readonly subscriptions: Subscription[] = [];
  presenceMonitoringFlag = false;
  presenceMonitoringData = [];
  public activeTourIndexLink: string;

  constructor(inject: Injector, private agreementService: AgreementService, private settingService: SettingsService,
    private dashboardService: DashboardService,
    public opportunitiesService: OpportunitiesService, private globalService: GlobalService,
    private employeeService: EmployeeService,
    private translate: TranslateService, private loginService: LoginService, private tourService: TourServiceService,
    private newmailservice: NewMailboxService,
    public dialog: MatDialog,
    private socketService: SocketService,
    public currentUserService: CurrentUserService
  ) {
    super(inject);
    this.languages = Language.languages;
    this.themeColor = ThemeColor.themeColor;
    try {
      this.tenantKey = CryptoJS.AES.decrypt(localStorage.getItem('tk'), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8);
    } catch (error) {
      this.tenantKey = '';  // to avoid exception
      console.log(error);
    }

    translate.setDefaultLang('en');
    this.subscription = this.settingService.userProfileUpdateEventListener.subscribe((res) => {
      if (res.fullname !== '' && res.avatar !== '') {
        this.userProfileImg = res.avatar;
        this.userName = res.fullname;
        this.settingService.userProfileUpdateEventListener.next(this.userProfileData);
      }
    });
    this.notificationSubscription = this.dashboardService.headerNotificationEventListener.subscribe((res) => {
      if (res) {
        this.notify(0, 'unread', '');
        this.dashboardService.headerNotificationEventListener.next(false);
      }
    });
    const presenceClock: Subscription = this.socketService.listen(SOCKET_KEY.PRESENCEMONITOR).subscribe(res => {
      if (res) {
        this.getPersonMonitoring(false);
      }
    });
    this.subscriptions.push(presenceClock);
  }
  ngOnInit() {
    this.loginType = this.configService.userType;
    // hidden presence monitoring from client view
    if (this.loginType !== 2) {
      this.getPersonMonitoring(false);
    }
    this.tourService.activeTourIndex.subscribe((index) => {
      this.activeTourIndex = index;
      const activeIndexData = this.tourService.customerTourData.find((x) => x.id === this.activeTourIndex);
      if (activeIndexData) {
        this.text = activeIndexData.text;
        this.label = activeIndexData.label;
        this.icon = activeIndexData.icon;
        this.activeTourIndexLink = activeIndexData.link;
      }
    });
    this.showPreviousLogin = (this.configService.impersonateAdminLoginId !== 0);
    this.limitStart = 0;
    this.password = this.loginService.getPass();
    this.changeTheme(this.themeClass, this.layoutFix, this.layoutBox, this.asideFloat);

    this.tenantKey = this.tenantKey.substring(1, this.tenantKey.length - 1);
    if (this.tenantKey) {
      this.getCompanies(this.tenantKey);
    }

    this.userName = this.configService.loginUserName;
    this.getUserProfileDetails();
    this.notify(this.limitStart, '', '');
    this.changeLangComp = this.fb.group({
      langControl: [''],
      compControl: ['']
    });
    // }


    this.filteredLang = this.changeLangComp.controls['langControl'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filteredLang(value))
      );
    /* this.filteredComp =  this.changeLangComp.controls['compControl'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filteredComp(value))
      ); */

  }

  private _filteredLang(value: string): string[] {
    // console.log(value);
    const filterValue = value;
    // console.log(value);
    return this.languages.filter(options => options.value.toLowerCase().includes(filterValue));
  }
  /*  private _filteredComp(value: string): string[] {
     const filterValue = value;
     return this.companyList.filter(option => option.name.toLowerCase().includes(filterValue));
   } */

  clickLink(link: string, $event: any, ownURL?: number) {

    if (ownURL) {
      this.routerDialog.navigateByUrl(link);

    } else {
      const newlink = link.split('/')[1];
      if (newlink === 'client') {
        this.dashboardService.createNewDashboardEventListener.next('client');
      } else if (newlink === 'leads') {
        this.dashboardService.createNewDashboardEventListener.next('leads');
      } else if (newlink === 'vendor') {
        this.dashboardService.createNewDashboardEventListener.next('vendor');
      } else if (newlink === 'opportunities') {
        this.dashboardService.createNewDashboardEventListener.next('opportunities');
      } else if (newlink === 'project') {
        this.dashboardService.createNewDashboardEventListener.next(link === 'support/project' ? 'project' : 'client-project');
      } else if (newlink === 'tickets') {
        this.dashboardService.createNewDashboardEventListener.next(link === 'support/tickets' ? 'tickets' : 'client-ticket');
      } else if (newlink === 'tasks') {
        this.dashboardService.createNewDashboardEventListener.next('tasks');
      } else if (newlink === 'bugs') {
        this.dashboardService.createNewDashboardEventListener.next(link === 'support/bugs' ? 'bugs' : 'client-bug');
      } else if (newlink === 'estimates') {
        this.dashboardService.createNewDashboardEventListener.next('estimates');
      } else if (newlink === 'rma') {
        this.dashboardService.createNewDashboardEventListener.next(link === 'support/rma' ? 'rma' : 'client-rma');
      } else if (newlink === 'mailbox') {
        this.dashboardService.createNewDashboardEventListener.next('mailbox');
      }

      if (link) {
        this.routerDialog.navigate([link]);
      }
    }
      }

  getCompanies(tenantKey) {
    this.loginService.getCompanies(tenantKey).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        if (res.data.length > 1) {
          this.hasMultipleCompanies = true;
        } else {
          this.hasMultipleCompanies = false;
        }
        this.changeLangComp.reset();
        this.compControl.reset();

        let compObj = {};
        let compPatchVal;
        this.hostname = window.location.hostname;
        res.data.forEach(resObj => {
          compObj = { 'value': resObj.companyId, 'label': resObj.name };
          this.dropArr.push(compObj);

          if (this.hostname === resObj.domain) {
            compPatchVal = resObj.companyId;
          }
        });
        this.companyList = this.dropArr;
        this.changeLangComp.patchValue({ compControl: compPatchVal });
      }
    });
  }
  useLanguage(language: string) {
    this.translate.use(language);
    // console.log(language);
    this.changeLangComp.reset();
    this.langControl.reset();
  }
  changeTheme(theme, fixed, boxed, float) {
    $('#themeDiv').removeClass().addClass(theme);
    const data = {
      theme: theme,
      layoutFixed: fixed,
      layoutBoxed: boxed,
      asideFloat: float
    };
    if (theme !== this.themeClass) {
      this.loginService.themeUpdate(data).subscribe(() => {
        this.configService.getAllConfigDetails();
        this.themeClass = theme;
      });
    }
  }

  changeCompany(companyId) {
    this.hostname = window.location.hostname;
    this.loginService.companyDetails(companyId, this.tenantKey).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.employeeService.detailsById(localStorage.getItem('userId')).subscribe((result: any) => {
          if (result.info.code === EStatusCode.OK) {
            // uncomment this for local testing
            // if (this.hostname == 'localhost') {
            //   var obj = {
            //     "email": result.data[0].email,
            //     "domain": this.hostname,
            //     "password": localStorage.getItem("passkey"),
            //     "ck": CryptoJS.AES.encrypt(JSON.stringify(res.data.companyDetails.companyKey), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString()
            //   };
            //   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
            //   var switchKey = encodeURIComponent(ciphertext);

            //   window.open("http://localhost:4000/login/?switchCompany=" + switchKey, "_blank");
            // } else {
            const obj = {
              'email': result.data[0].email,
              'domain': res.data.companyDetails.domain,
              'password': localStorage.getItem('passkey'),
              'ck': CryptoJS.AES.encrypt(JSON.stringify(res.data.companyDetails.companyKey), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString()
            };

            const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
            const switchKey = encodeURIComponent(ciphertext);
            window.open('https://' + res.data.companyDetails.domain + '/login/?switchCompany=' + switchKey, '_blank');
            // }
          }
        });
      } else {
        this.toastr.error('Something went wrong.');
      }
    });
  }
  logout() {
    this.loginService.logout().subscribe(() => {
      this.socketService.disconnectSocket();
      localStorage.clear();
      this.configService.smtpUsername = '';
      this.newmailservice.closeAll();
      this.currentUserService.reset();
      this.routerDialog.navigate(['/login']);
      this.titleService.setTitle('CodeNgine');
    });
  }
  userProfile() {
    this.titleService.setTitle('Update Profile');
    this.routerDialog.navigate(['/utilities/profile/update'], {
      replaceUrl: true
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
  getUserProfileDetails() {
    this.settingService.getUserProfile().subscribe(
      (response: IResponse) => {
        if (response.info.code === EStatusCode.OK) {
          const data = JSON.stringify(response.data[0]);
          this.userData = JSON.parse(data);
          this.userProfileImg = this.userData.avatar;
        }
      }, (error) => {
        console.log(error);
      });
  }

  notify(count, type, allDetails) {
    const filter = { 'start': count, 'limit': 10, 'type': type };
    this.loginService.getNotifyList(filter).subscribe((res: Notification) => {
      if (res.info.code === EStatusCode.OK) {
        this.setNotifyData(res, type);
      } else {
        if (type === 'all') {
          this.notifyList = [];
          this.allCount = 0;

        } else if (type === 'read') {
          this.readNotifyList = [];
          this.readCount = 0;

        } else if (type === 'unread') {
          this.unReadNotifyList = [];
          this.unReadCount = 0;

        } else {
          this.notifyList = [];
          this.allCount = 0;

          this.unReadNotifyList = [];
          this.unReadCount = 0;

          this.readNotifyList = [];
          this.readCount = 0;
        }
      }
      if (!allDetails && type) {
        this.notifyScrollList = this.notifyScrollList.concat(this.notifyList);
        this.unReadScrollList = this.unReadScrollList.concat(this.unReadNotifyList);
        this.readNotifyScrollList = this.readNotifyScrollList.concat(this.readNotifyList);
      } else if (!type) {
        this.notifyScrollList = this.notifyList;
        this.unReadScrollList = this.unReadNotifyList;
        this.readNotifyScrollList = this.readNotifyList;
      }

    });
  }

  // Clear notification
  clearNotification(notifyUserApplicationId, allDetails) {
    this.loginService.readNotification(notifyUserApplicationId).subscribe((res: Notification) => {
      if (res.info.code === EStatusCode.OK) {
        this.unReadCount = this.unReadCount - 1;
        this.readCount = this.readCount + 1;

        // all notification list
        let pos = this.notifyScrollList.findIndex(x => x.notifyUserApplicationId === notifyUserApplicationId);
        if (pos >= 0) {
          this.notifyScrollList[pos].isRead = '1';
        }

        // un read notification list
        pos = this.unReadScrollList.findIndex(x => x.notifyUserApplicationId === notifyUserApplicationId);
        if (pos >= 0) {
          this.unReadScrollList[pos].isRead = '1';
          this.onChangeTab({ index: 1, allDetails: '' });
        }

        // // read notification list
        // pos = this.readNotifyScrollList.findIndex(x => x.notifyUserApplicationId === notifyUserApplicationId);
        // if (pos > -1) {
        //   this.readNotifyScrollList[pos].isRead = '1';
        // }
      }
    });
  }

  /* route to module details*/
  viewNotificationDetails(module, notifyUserApplicationId, moduleId, type, allDetails) {
    if (module === 'payment') {
      this.routerDialog.navigateByUrl('accounting/paymentsReceived/details/' + moduleId);
    } else if (module === 'project') {
      this.routerDialog.navigateByUrl('support/project/details/' + moduleId);
    } else if (module === 'invoice') {
      this.routerDialog.navigateByUrl('accounting/invoice/details/' + moduleId);
    } else if (module === 'tickets') {
      this.routerDialog.navigateByUrl('support/tickets/details/' + moduleId);
    } else if (module === 'opportunity') {
      this.routerDialog.navigateByUrl('sales/opportunities/details/' + moduleId);
    } else if (module === 'task') {
      this.routerDialog.navigateByUrl('admin/tasks/details/' + moduleId);
    } else if (module === 'client') {
      this.routerDialog.navigateByUrl('admin/agreement/detail/' + moduleId);
      this.agreementService.setAgreementType('dashboard');
    } else if (module === 'vendor') {
      this.routerDialog.navigateByUrl('admin/agreement/detail/' + moduleId);
      this.agreementService.setAgreementType('dashboard');
    } else if (module === 'deadline') {
      this.opportunitiesService.oppoDetail.msg = 'list';
      this.opportunitiesService.oppoDetail.oppoId = moduleId;
      this.opportunitiesService.oppoDetail.notificationType = 'notification-deadline';
      this.routerDialog.navigateByUrl('sales/opportunities/details/' + moduleId);
    } else if (module === 'estimate') {
      this.routerDialog.navigateByUrl('sales/estimates/details/' + moduleId);
    }
    /* change notification status to read */
    if (type !== 'read') {
      this.clearNotification(notifyUserApplicationId, allDetails);
    }
  }

  onScrollDown(type) {
    if (type === 'all') {
      this.allLimitStart = this.limitStart = this.allLimitStart + 10;

    } else if (type === 'read') {
      this.readLimitStart = this.limitStart = this.readLimitStart + 10;

    } else if (type === 'unread') {
      this.unReadLimitStart = this.limitStart = this.unReadLimitStart + 10;

    } else {
      this.limitStart = this.limitStart + 10;
    }
    this.notify(this.limitStart, type, '');
  }

  setNotifyData(res, type) {
    if (type === 'all') {
      this.notifyList = res.data.list;
      this.allCount = res.data.count;

    } else if (type === 'read') {
      this.readNotifyList = res.data.list;
      this.readCount = res.data.count;

    } else if (type === 'unread') {
      this.unReadNotifyList = res.data.list;
      this.unReadCount = res.data.count;
    } else {
      this.notifyList = res.data.allList;
      this.allCount = res.data.allCount;

      this.unReadNotifyList = res.data.unReadList;
      this.unReadCount = res.data.unReadCount;

      this.readNotifyList = res.data.readList;
      this.readCount = res.data.readCount;
    }
  }

  onChangeTab(event) {
    if (event.index === 1) {
      this.unReadScrollList = [];
      this.unReadLimitStart = 0;
      this.notify(0, 'unread', event.allDetails);
    } else if (event.index === 2) {
      this.readNotifyScrollList = [];
      this.readLimitStart = 0;
      this.notify(0, 'read', event.allDetails);
    } else {
      this.notifyScrollList = [];
      this.allLimitStart = 0;
      this.notify(0, 'all', event.allDetails);
    }
  }
  previousLogin() {
    const userId = Number(localStorage.getItem('userId'));
    this.employeeService.preLogin(userId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        const ask = res.data['accessToken'];
        const id = res.data.initialDetail.userDetails.userId;
        localStorage.setItem('accessToken', ask);
        localStorage.setItem('userId', id);
        localStorage.removeItem('isImpersonateUser');
        window.location.replace('/admin/dashboard');
      }
    });
  }
  /* To enable and disable user notifications on toogle */
  notificationToggle() {
    this.notification = this.notification ? false : true;
    const notificationSubscribe: Subscription = this.globalService.notificationToggle(this.notification).subscribe((response: IResponse) => {
      if (response.result.info.code === EStatusCode.OK) {
        this.configService.getAllConfigDetails();
        this.toastr.success('notification Updated!');
      }
    }, (error) => {
      this.toastr.error('notification Update failed');
    });
    this.subscriptions.push(notificationSubscribe);
  }

  utilikoTour(): void {
    this.tourService.isCustomerTourStart.next(true);
    this.router.navigateByUrl('admin/dashboard');
    this.tourService.activeTourIndex.next(1);
  }

  openDialog(): void {
    this.getPersonMonitoring(true);
  }

  getPersonMonitoring(dialogFlag) {
    const presenceMonitoring: Subscription = this.loginService.getPersonMonitoring().subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK && res.data.data.length !== 0) {
        this.presenceMonitoringFlag = true;
        this.presenceMonitoringData = res.data.data;
        this.loginService.clockInAndPresentData.next(this.presenceMonitoringData);
        if (dialogFlag === true) {
          this.dialog.open(PresenceMonitoringComponent, {
            panelClass: ['Presence-moitoring-modal', this.configService.userTheme],
            width: '1100px',
            data: this.presenceMonitoringData,
          });
        }
      } else {
        this.presenceMonitoringFlag = false;
        this.loginService.clockInAndPresentData.next(null);
      }
    });
    this.subscriptions.push(presenceMonitoring);
  }
}
