<div class="review-submit-page">
  <div class="review-wrapper">
      <div class="comp-logo">
        <img src="{{ companyLogo }}" onError="this.src='/assets/img/default_company_logo.png'"/>
      </div>
      <div class="review-form">
        <form (ngSubmit)="submitReview()">
          <div class="form-group">
            <h4 *ngIf="moduleType === 'tickets'" class="ticket-subject">{{ticketSubject}}</h4>
            <h4 *ngIf="moduleType === 'tickets'" class="ticket-name">{{ticketName}}</h4>
            <h4 *ngIf="moduleType === 'project'" class="ticket-name">{{projectName}}</h4>
            <div class="rating-wrap">
                <button type="button" mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
                  (click)="onClick(i+1)" [matTooltip]="ratingtooltipArr[i]" class="btn-star">
                  <mat-icon>{{rating >= i + 1 ? 'star' : 'star_border'}}</mat-icon>
                </button>
            </div>
          </div>
          <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                  <textarea matInput placeholder="Write your review here" [formControl]="reviewMessage"></textarea>
              </mat-form-field>
              <div class="error" *ngIf="submitted && reviewMessage.errors?.required">
                  Please enter your review 
              </div>
          </div>
          <div class="form-group p-t-20 text-center">
            <button class="btn btn-submit" type="submit">Submit Review</button>
          </div>
        </form>
      </div>
      <div class="footer-box-body">
        <div> © <a href="https://codengine.co/" target="_blank">CodeNgine Portal.</a>All rights reserved.
        </div>
    </div>
  </div>
</div>


