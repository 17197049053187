<div class="proposals-signature-page public-page">
  <div class="proposals-signature-wrapper">
    <mat-toolbar class="theme-bg-color" [ngClass]="themeData" >
      <div class="logo-container">
        <img src="{{companyDetails?.companyLogo}}"
              onError="this.src='/assets/img/default_company_logo.png'">
        <mat-label class="headerName themeColor text-white p-l-20" [ngClass]="themeData">{{companyDetails?.companyName}}</mat-label>
      </div>
    </mat-toolbar>
    <div class="proposal-card" [class.expiry-page]="isProposalExpired">
      <div class="proposal-card-inner bg-white" *ngIf="!isProposalExpired && proposalData">
        <div class="actions-buttons-wrapper">
          <div class="col-left">
            <!-- <button class="btn pdf" mat-button #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Export as PDF"
            [matTooltipClass]="'basic-tooltip'" (click)="openDialog()" name="exportPDF">
              <i class="fa fa-file-pdf-o colorPDF"></i>
            </button> -->
            <button class="btn pdf" mat-button #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Print Proposal"
            [matTooltipClass]="'basic-tooltip'" (click)="print()" name="printProposal">
              <i class="fas fa-print text-danger"></i>
            </button>
          </div>
          <div class="col-right">
              <button  *ngIf="proposalData?.status === 'PENDING'"
              mat-raised-button class="btn btn-rounded button-decline" name="proposalDecline"
              #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Decline Proposal"
              [matTooltipClass]="'basic-tooltip'" (click)="declineProposal()">
                Decline
              </button>
              <button *ngIf="proposalData?.status === 'DECLINED' || proposalData?.status === 'PENDING'"
              mat-raised-button class="btn btn-rounded button-accept" name="proposalAccept"
              #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Accept Proposal" [matTooltipClass]="'basic-tooltip'"
              (click)="acceptProposal()">
                Accept
              </button>
          </div>
        </div>
        <div class="proposal-content" >
          <div class="proposal-details">
            <div class="client-info-wrap">
              <div class="address-wrapper">
                <h4 class="m-t-0">
                  <a class="url">{{proposalData?.clientLeadName}}</a>
                </h4>
                <address>
                  <p class="m-b-0">{{proposalData?.billingAddress}}</p>
                  <p class="m-b-0">{{proposalData?.billingCity}} {{proposalData?.billingState}} {{proposalData?.billingZipCode}}</p>
                  <p class="m-b-0">{{proposalData?.country}}</p>
                </address>
              </div>
            </div>
            <div class="proposal-info-wrap">
              <div class="proposal-info">
                <div class="proposal-field">
                  <div class="field-label">Proposal No</div>
                  <div class="field-value"><strong>{{proposalData?.referenceNo}}</strong></div>
                </div>
                <div class="proposal-field">
                  <div class="field-label">Proposal Status</div>
                  <div class="field-value">
                    <span class="status-button" [ngClass]="[
                      proposalData?.status === 'ACCEPTED' ? 'successButton' : '',
                      proposalData?.status === 'DECLINED' ? 'dangerButton' : '',
                      proposalData?.status === 'PENDING' ? 'warningButton' : '',
                      proposalData?.status === 'EXPIRED' ? 'progressButton' : ''
                    ]">
                      {{proposalData?.status}}
                    </span>
                  </div>
                </div>
                <div class="proposal-field">
                  <div class="field-label">Expires On</div>
                  <div class="field-value">{{proposalData?.validUntil | commonDate : 'date'}}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="printProposal" class="proposal-template-html" [innerHTML]="proposalData?.template">
          </div>
        </div>
      </div>
      <div class="expiry_wrapper text-center" *ngIf="isProposalExpired">
        <div class="expiry-img m-b-20">
          <img src="assets/img/Group 23772.png">
        </div>
        <div class="expiry-text m-t-20">
          <p class="expiry-title theme-text-color">This proposal has expired.</p>
          <p>The proposal link you are looking for is no longer valid. If you have any questions or would like to discuss further, please feel free to contact our agent. We appreciate your consideration of our proposal!</p>
        </div>
      </div>
    </div>
    <footer class="page-footer view_footer">
      <div class="footer-copyright">
        <p class="m-b-0">Copyright© <a style="color:#5d9cec" href="https://codengine.co" target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.</p>
        <p class="m-b-0">{{version}}</p>
      </div>
    </footer>
  </div>
</div>
