
<div class="navbar-header sidebar-navbar-brand">
  <a class="navbar-brand" style="text-align: center">
    <!--CodeNgine-->
    <img *ngIf="companyLogo; else defaultLogo" src="{{companyLogo}}"  alt= "CodeNgine"
      onError="this.src='/assets/img/default_company_logo.png'">
    <ng-template #defaultLogo>
      <img src="../../../assets/img/default_company_logo.png"  alt= "CodeNgine">
    </ng-template>
  </a>
</div>
<mat-nav-list appAccordion id="matnavList" multi="false" >
    <app-menu-list-item *ngFor="let item of navItems" multi="false" [item]="item" [navItemList]="navItems"></app-menu-list-item>
</mat-nav-list>

