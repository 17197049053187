<div class="main-container">


    <mat-sidenav-container class="example-sidenav-container" [style.margin-top.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'"
                     fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">

            <app-sidebar></app-sidebar>


        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content>
            <mat-toolbar color="primary" class="topbar telative">
                <!-- <div class="navbar-header">
                    <a class="navbar-brand" style="text-align: center" href="index.html">
                        CodeNgine
                    </a>
                </div> -->

                <!-- ============================================================== -->
                <!-- sidebar toggle -->
                <!-- ============================================================== -->
                <div class="header_site_name">
                    <button id="toggle-sidebar" mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
                        <mat-icon class="header-icon">menu</mat-icon>
                    </button>
                    <!--<span> CodeNgine Portal</span>-->
                    <!--<mat-label> {{companyName | titlecase}}</mat-label>-->
                    <mat-label> {{ companyName | titlecase }}
                    </mat-label>
                </div>
                <!-- ============================================================== -->
                <!-- app header component - style you can find in header.scss / header.component.ts-->
                <!-- ============================================================== -->
                <div class="header_search_call">
                    <app-header></app-header>
                </div>
            </mat-toolbar>
            <!-- ============================================================== -->
            <!-- End Topbar - style you can find in pages.scss -->
            <!-- ============================================================== -->
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Header container - style you can find in header.scss -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->

        <mat-sidenav-content class="page-wrapper">

            <div class="page-content" id="pageBodyContent" #scrollBody>
                <router-outlet></router-outlet>
                <app-footer></app-footer>
            </div>
            <app-scroll-top-button [scrollBody]="scrollBody" [type]="'full'"></app-scroll-top-button>
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>
<app-spinner></app-spinner>
<div class="timer-content" *ngIf="isUserLoggedIn">
    <app-task-timer></app-task-timer>
</div>
<ng-container *ngIf="showSoftphone">
    <app-softphone></app-softphone>
</ng-container>
<!-- <div class="pbx-popup">
   <app-pbx-popup></app-pbx-popup>
</div> -->
