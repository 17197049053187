<div #invoice id="jobCom">
  <div class="view_header">
    <mat-toolbar class="themeColor" [ngClass]="themeData">
      <img src="{{companyLogo}}" onError="this.src='assets/img/default_company_logo.png'">
      <span *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: black">{{companyName}}</span>
      <span *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: white">{{companyName}}</span>
      <span class="example-fill-remaining-space"></span>
    </mat-toolbar>
  </div>
  <div *ngIf="!showResultLabel">
  <section  class="job-main-content">
    <div class="form-group filter-div">
      <div class="card-block" id="namecheck">
        <mat-form-field appearance="outline">
          <mat-label>Search Job</mat-label>
          <input matInput [(ngModel)]="query" class="form-control" placeholder="Search Job">
        </mat-form-field>
      </div>
    </div>
    <mat-card *ngFor="let item of jobs  | search:'jobTitle,description':query" class="arrow-right">
      <a name="leadNameLink" href="{{item.url}}" style="cursor: pointer">
        <mat-card-header >
        <mat-card-title>
            <h3>
              {{item.jobTitle}}
            </h3>
          </mat-card-title>
          <mat-card-subtitle>
            <div class="row" style="min-height: 30px">
                <div class="col-md-12">
                  <span class="notes-message" *ngIf="item.description" [innerHtml]="item.description | safe: 'html'"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <i class="material-icons">
                    schedule
                  </i> {{item.postedDate | commonDate : 'date'}}
                  <i class="material-icons">
                    work
                  </i> {{item.employmentType}}
                </div>
                <div class="col-md-6"></div>
              </div>
            </mat-card-subtitle>
        </mat-card-header>
      </a>
    </mat-card>
  </section>
  <footer class="page-footer view_footer" style="padding-top:5px;">
      <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co"
        target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
        <p class="alignright">{{version}}</p>
      </div>
    </footer>
  </div>
  <div *ngIf="showResultLabel">
    <div class="center">
      <p style="font-size: 50px">Hey Techie!</p>
      <p style="font-size: 25px">We don't have any openings currently.</p>
      <p style="font-size: 15px">Keep checking here for new opportunities.</p>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
