<div class="hold-transition utiliko-login-page utiliko-login_main clearfix row align-items-center d-flex flex-wrap">

  <div class="utiliko-login_page_content m-0 col-md-6 col-sm-12 col-xs-12 text-center tw-text-white">
    
  </div>
    <div class="login-box col-md-6 col-sm-12 col-xs-12 vh-100 d-flex align-items-center justify-content-center position-relative">

      <div class="login-box-body mx-3">

        <form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="md-float-material form-material" action="javascript:">
              <div class="row m-b-20">
                  <h1>Welcome back!</h1>
                  <p class="text-center small-content">Log back into your account</p>
              </div>
              <div class="form-group form-primary textFields w-100">
                <mat-form-field appearance="outline" class="w-100 tw-mb-4">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="Email" name="email" type="email" formControlName="email" required>
                  <mat-icon matPrefix><i class="fa fa-envelope" aria-hidden="true"></i></mat-icon>
                  <mat-error class="messages text-danger" *ngIf="(!submitted) && (f.email.invalid && (f.email.touched) && f.email.errors?.required)">Email
                    is required</mat-error>
                  <mat-error class="messages text-danger" *ngIf="(submitted) && (f.email.errors?.required)">Email is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Password</mat-label>
                  <input matInput placeholder="Password" [attr.type]="hideDevice ? 'password' : 'text'" name="password" formControlName="password" required>
                  <mat-icon matPrefix><i class="fa fa-lock" ></i></mat-icon>
                  <mat-error class="messages text-danger" *ngIf="(!submitted) && (f.password.invalid && (f.password.touched) && f.password.errors?.required)">Password
                    is required</mat-error>
                  <mat-error class="messages text-danger" *ngIf="(submitted) && (f.password.errors?.required)">Password is
                    required</mat-error>
                  <button type="button" mat-icon-button matSuffix (click)="hideDevice = !hideDevice"
                      [attr.aria-label]="'Hide device password'" class="device-password-btn">
                      <mat-icon>{{hideDevice ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </mat-form-field>
                <ng-container *ngIf="showCaptcha">
                  <app-re-captcha (disableLoginBtn)="disableLoginBtn($event)"></app-re-captcha>
                </ng-container>

              </div>
              <div class="row m-t-25 text-left">
                <div class="col-md-12">
                  <div class="pull-left">
                    <mat-checkbox name="rememberMe" [(ngModel)]="rememberMeFlag" (change)="checkSelected()" formControlName="rememberMe" value="" type="checkbox"> Remember me
                    </mat-checkbox>
                  </div>
                  <div class="pull-right">
                    <a [routerLink]="['/reset-password']" class="forgot_pass"><b>Reset Password?</b></a>
                  </div>
                </div>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12 position-relative">
                  <button [disabled]="disableLoginButton" name="submit" type="submit" class="btn btn-block text-center m-b-20 proceedBtn">login</button>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                </div>
              </div>
        </form>

      </div>
      <div class="footer-box-body w-100 mt-3 position-absolute bottom-0">
        <div class="footer-copyright text-center">Copyright © <a style="color:#5d9cec" href="https://codengine.co " target="_blank">CodeNgine Portal.</a> All rights reserved.
        </div>
      </div>
    </div>
  </div>
  <app-spinner></app-spinner>
