<script src="start-campaign.component.ts"></script>
<div *ngIf="expired">
    <div class="main-content">
        <div class="view_header">
            <mat-toolbar class="themeColor" [ngClass]="themeData">
                <img src="{{companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
            </mat-toolbar>
        </div>
        <div class="background-clr">
            <div class="expired_page_div">
                <img class="image-set" src="../../../../assets/img/Group 23772.png">
                <h4 [ngClass]="themeData" class="survey-text theme-text-color"><b>Your Survey has expired</b></h4>
                <p class="para-content">The survey page you are looking for has expired</p>
            </div>
        </div>
        <footer class="page-footer view_footer" style="padding-top:5px;">
            <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co"
                    target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
                <p class="alignright">{{version}}</p>
            </div>
        </footer>
    </div>
</div>
<div *ngIf="Notexpired" class="main-div">
    <div class="top-border">
        <div class="scrollable-area">
            <mat-card [style.minHeight]="'100%'" class="root-height">
                <mat-card-title class="text-center">{{title}}<span style="color: red;">{{name}}</span></mat-card-title>
                <mat-divider></mat-divider>

                <mat-card-content>
                    <div *ngIf="!formSubmit" class="text-center text-msg" [innerHTML]="content"></div>
                    <mat-form-field appearance="outline" id="textBox" class="text-center">
                        <input matInput type="text" placeholder="Enter survey code" required [(ngModel)]="verify"
                            (keydown.enter)="surveyName()">
                    </mat-form-field>

                    <div id="allQues" class="ques-style">
                        <div class="h5 head-style d-flex">
                            <span class="m-r-10">(Q).</span>
                            <div [innerHTML]="displayQues?.surveyQuestionName | safe:'html'" class="tinyClass article-body tw-px-2.5"></div>
                        </div>

                        <ng-container *ngIf="displayQues?.gallery.length > 0">
                            <app-lightbox-gallery [galleryData]="displayQues?.gallery" [slidesToShow]="slidesToShow" [showCount]="true"></app-lightbox-gallery>
                            <hr>
                        </ng-container>

                        <ng-container *ngIf="displayQues?.surveyQuestionType === 'text'">
                            <div class="quizTextbox top-s">
                                <mat-form-field appearance="outline">
                                <input matInput placeholder="Type your text here" required [(ngModel)]="textAns"
                                        (keydown.enter)="valueFunc('text','NA');nextQuestion()" maxlength="1000"
                                        >
                                </mat-form-field>
                            <span *ngIf="textAns.length > 749" class="pull-right char c-font"><em>{{1000 - textAns.length}} characters left </em></span>
                            </div>
                        </ng-container>

                    <ng-container *ngIf="displayQues?.surveyQuestionType === 'multilinetext'">
                        <div class="quizTextbox top-s">
                        <mat-form-field appearance="outline">
                            <textarea matInput placeholder="Type your text here" required [(ngModel)]="multiTextAns"
                                maxlength="1000" cdkTextareaAutosize class="text-size"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="6"
                            ></textarea>
                        </mat-form-field>
                        <span *ngIf="multiTextAns.length > 749" class="pull-right char c-font"><em>{{1000 - multiTextAns.length}} characters left </em></span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="displayQues?.surveyQuestionType === 'dropdown'">
                            <mat-form-field class="top-s">
                                <mat-label>Select an option</mat-label>
                                <mat-select [(value)]="prevSelected">
                                    <mat-option class="panel-wrap" (click)="valueFunc(label.label,label.score);jumpQues(label.goTo)"
                                                *ngFor="let label of displayQues?.questionOptions;let i = index;trackBy:trackByDisplayQues"
                                                value="{{ label.label }}-{{ displayQues?.surveyQuestionId }}"><h6 class="wrap">{{label.label}}</h6>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="displayQues?.surveyQuestionType === 'radio_choice'">
                            <ng-container *ngIf="displayQues?.displayStyle === 'V'">
                                <mat-radio-group aria-labelledby="startCamp-radio-group-label" [(ngModel)]="prevSelected"
                                                class="startCamp-radio-group">
                                    <mat-radio-button class="startCamp-radio-button"
                                                    (click)="valueFunc(label.label,label.score);jumpQues(label.goTo)"
                                                    *ngFor="let label of displayQues?.questionOptions;let i = index;trackBy:trackByDisplayQues"
                                                    value="{{ label.label }}-{{ displayQues?.surveyQuestionId }}">
                                        <h6 class="wrap">{{label.label}}</h6>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </ng-container>
                            <div class="allignText">
                                <ng-container *ngIf="displayQues?.displayStyle === 'H'">
                                    <mat-radio-group  aria-labelledby="startCamp-radio-group-label" [(ngModel)]="prevSelected"
                                                    class="horizontal-view ,hview">
                                        <mat-radio-button class="startCamp-radio-button  "

                                                        (click)="valueFunc(label.label,label.score);jumpQues(label.goTo)"
                                                        *ngFor="let label of displayQues?.questionOptions;let i = index;trackBy:trackByDisplayQues"
                                                        value="{{ label.label }}-{{ displayQues?.surveyQuestionId }}">
                                            <h6 class="wrap" >{{label.label}}</h6>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="displayQues?.surveyQuestionType === 'nps'">
                            <div *ngFor="let ans of displayQues?.questionOptions;let i = index;trackBy:trackByDisplayQues" class="nps-type">
                                <h6>{{ans.labelLeft}}</h6>
                                <h6>{{ans.labelRight}}</h6>
                            </div>
                            <div class="nps-options">
                                <h6 *ngFor="let nps of staticNps trackBy:trackByStaticNps">{{nps}}</h6>
                            </div>
                            <mat-radio-group class="likeRadio" [(ngModel)]="prevSelected">
                                <mat-radio-button *ngFor="let nps of staticNps;let i = index; trackBy:trackByStaticNps" (click)="valueFunc(nps,nps);nextQuestion()"
                                                value="{{ nps }}-{{ displayQues?.surveyQuestionId }}"></mat-radio-button>
                            </mat-radio-group>
                        </ng-container>

                        <ng-container *ngIf="displayQues?.surveyQuestionType === 'yes_no'">

                            <mat-radio-group aria-labelledby="startCamp-radio-group-label" [(ngModel)]="prevSelected"
                                            class="startCamp-radio-group">
                                <mat-radio-button class="startCamp-radio-button"

                                                (click)="valueFunc(label.label,label.score);jumpQues(label.goTo)"
                                                *ngFor="let label of displayQues?.questionOptions;let i = index;trackBy:trackByDisplayQues"
                                                value="{{ label.label }}-{{ displayQues?.surveyQuestionId }}">
                                    <h6 class="wrap">{{label.label}}</h6>
                                </mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                    </div>
                </mat-card-content>

                <form *ngIf="formSubmit" [formGroup]="submitDetails" class="text-center user-detail-form">
                    <div class="new-row">
                        <div class="column-two">

                            <mat-form-field class="startCamp-full-width tw-mt-4" appearance="outline">

                                <mat-label>First Name</mat-label>
                                <input type="text" placeholder="First Name"
                                    formControlName="firstName"
                                    matInput name="firstName" required>
                            </mat-form-field>

                            <mat-form-field class="startCamp-full-width tw-mt-4" appearance="outline">

                                <mat-label>Last Name</mat-label>
                                <input type="text" placeholder="Last Name"
                                    formControlName="lastName"
                                    matInput name="lastName" required>
                            </mat-form-field>

                            <mat-form-field class="startCamp-full-width tw-mt-4" appearance="outline">

                                <mat-label>Reply Email</mat-label>
                                <input type="email" placeholder="Reply Email" aria-label="Number"
                                    formControlName="replyEmail"
                                    matInput name="replyEmail" required>
                            </mat-form-field>                 
                            <mat-form-field class="startCamp-full-width hide-icon tw-mt-4" appearance="outline">
                                
                                <mat-label>Phone</mat-label>
                                <input type="tel" placeholder="Phone" aria-label="Number"
                                    formControlName="phone"
                                    matInput name="phone"  min="0" required (keypress)="onKeyPress($event)"
                                    pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$">
                            </mat-form-field>

                            <!-- <mat-form-field class="startCamp-full-width" appearance="outline">
                                <input required date="true" matInput formControlName="dateOfBirth" [matDatepicker]="picker" (focus)="picker.open()"
                                    name="dateOfBirth" placeholder="Date Of Birth">
                                <mat-label>Date Of Birth</mat-label>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field> -->
                            
                            <mat-form-field class="startCamp-full-width tw-mt-4" appearance="outline">
                            
                                <mat-label>Zip</mat-label>
                                    <input              
                                        type="tel"
                                        placeholder="Zip"
                                        formControlName="zip"
                                        matInput
                                        maxlength="6"
                                        pattern="^(?!0{3})[0-9]{4,6}$"
                                        (keypress)="onKeyPress($event)" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <button mat-raised-button class="btn-submit form-button-40 btn-style strt-survey-btn" color="primary" id="nextButton"
                        (click)="this[buttonClick]()">
                    {{buttonName}}
                </button>
                <button mat-raised-button color="primary" [disabled]="!submitDetails.valid" class="btn-submit form-button-40 btn-style top-s"
                    id="submitButton" (click)="submitData()">
                    Submit
                </button>
                <div [class.next-button-align]="currentIndex === 0" class="next-pre-align">
                <button mat-raised-button color="red" id="prevQues" *ngIf="backButton" class="back-button"
                        (click)="prevQuestion()">Previous
                </button>
                <button mat-raised-button class="next-button" *ngIf="nextButton" (click)="gotoNextQues()" name="next-btn">Next</button>
            </div>
            </mat-card>
        </div>
    </div>
</div>
<app-spinner></app-spinner>
