<div *ngIf="!error" #invoice id="invCom">
  <div class="view_header">
    <mat-toolbar class="themeColor" [ngClass]="themeData">
      <img src="{{companyLogo}}" onError="this.src='assets/img/default_company_logo.png'">
      <span *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: black">{{companyName}}</span>
      <span *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: white">{{companyName}}</span>
    </mat-toolbar>
  </div>
  <section class="inv-main-content">
    <div id="inv-detail-content" class="clearfix">

      <div class="custom-item-btn pull-right view-print hand-cursor" mat-button #tooltip="matTooltip" matTooltipPosition="above"
        matTooltip="Print RMA" style="cursor: pointer" (click)="printDetail(rmaId,rmaDetList?.clientName)">
        <i class="fas fa-print text-danger"></i>
      </div>
      <div class="custom-item-btn pull-right" mat-button #tooltip="matTooltip" matTooltipPosition="above"
           matTooltip="{{'common.pdf' | translate}}" (click)="downloadPDF(rmaId,rmaDetList?.clientName)">
        <i class="fa fa-file-pdf-o colorPDF view-pdf" ></i></div>

      <div class="inv-sub-main-content">
        <div class="inv-sub-header">{{rmaDetList?.rmaNo}}
        </div>
        <mat-divider [vertical]="true"></mat-divider>

        <div class="inv-sub-detail-content clearfix">
          <div class="all_details row tw-p-2">
            <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4 pull-left client_info">
              <div class="clearfix">
                <span class="pull-left">
                  <h4>{{configService.companyLegalName}}</h4>
                </span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{configService.companyAddress}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{configService.companyCity}},{{configService.companyState ? configService.companyState + ',' : ''}}{{configService.companyZipCode}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{configService.companyCountry}}</span>
              </div>
              <div class="clearfix address" *ngIf="rmaDetList?.companyPhone !== ''">
                <span class="pull-left light-font">{{ 'rma.rma-detail.phone' | translate }}:
                  {{configService.companyPhone | commonDate: 'phone_format'}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-xs-4 col-md-4 col-sm-4 br pv hidden-print client_info">
              <div class="clearfix">
                <span class="pull-left">
                  <h4>{{rmaDetList?.clientName}}</h4>
                </span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{rmaDetList?.billingAddress}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{dataRma | addressFormat: 'city_state_zip'}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{rmaDetList?.country}}</span>
              </div>
              <div class="clearfix address" *ngIf="rmaDetList?.phone">
                <span class="pull-left light-font">{{ 'rma.rma-detail.phone' | translate }}:
                  {{rmaDetList?.phone | commonDate: 'phone_format'}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4  pull-right client_status_detail">
              <div class="m-t-5 clearfix">
                <div class="light-font">{{'rma.rma-detail.status' | translate}}:
                  <span class="tag-btn f-r"
                    [ngClass]=getClassByStatus()>
                    {{rmaStatus}} </span>
                </div>
              </div>
              <div class="clearfix invdate">
              <div class="divider"></div>
              <div class="clearfix paydue">
              </div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="inv-line"></div>
          <table mat-table [dataSource]="dataSource" class="inv-table">
            <ng-container matColumnDef="item_name">
              <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'rma.rma-form.itemName' |
                  translate }}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.itemName}}</td>
            </ng-container>
            <ng-container matColumnDef="item_desc">
              <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'rma.rma-form.description' |
                  translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="inv-item-table" style="max-width: 290px;">
                {{element.itemDesc}}
              </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'rma.rma-form.qty' | translate }}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="reason">
              <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'rma.rma-form.reason' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.reason}}</td>
            </ng-container>
            <ng-container matColumnDef="requested_quantity">
              <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'rma.rma-form.reqQty' | translate }}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.reqQuantity}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="inv-line"></div>
        </div>
      </div>
    </div>
    </div>
  </section>
  <footer class="page-footer view_footer" style="padding-top:5px;">
    <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co"
        target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
      <p class="alignright">{{version}}</p>
    </div>
  </footer>
</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px">Please check your entered Id.</p>
  </div>
</div>

<div id="print" [hidden]="!show">
  <table style="width: 100%">
    <tbody>
      <tr>
        <td>
          <div>
            <div class="inv-sub-main-content">
              <div class="waterMark">
                <p class="styleWtareMarkGreen" *ngIf="rmaDetList?.status === '2' || rmaDetList?.status === '3' || rmaDetList?.status === '4'">
                  {{rmaStatus}}</p>
                <p class="styleWtareMarkRed" *ngIf="rmaDetList?.status !== '0'|| rmaDetList?.status === '1'">
                  {{rmaStatus}}</p>
              </div>
              <div class="themeColor" style="background-color: white">
                <div style="display: inline-block">
                  <img src="{{companyLogo}}" width="90px;" style="height: 60px;"
                       onError="this.src='/assets/img/default_company_logo.png'"> </div>
                <div class="headerName themeColor ">{{companyName}}</div>
              </div>
              <div class="inv-line"></div>
              <div class="inv-sub-detail-content clearfix">
                <div style="min-height: 200px">
                  <div class="addressAlign">
                    <div class="clearfix">
                      <span class="pull-left">
                        <h4>{{rmaDetList?.clientName}}</h4>
                      </span>
                    </div>
                    <div class="clearfix address">
                      <span class="pull-left light-font">{{rmaDetList?.billingAddress}}</span>
                    </div>
                    <div class="clearfix">
                      <span class="pull-left light-font">{{dataRma | addressFormat: 'city_state_zip'}}</span>
                    </div>
                    <div class="clearfix">
                      <span class="pull-left light-font">{{rmaDetList?.country}}</span>
                    </div>
                    <div class="clearfix" *ngIf="rmaDetList?.phone !== ''">
                      <span class="pull-left light-font">{{ 'rma.rma-detail.phone' | translate }}
                        : {{rmaDetList?.phone | commonDate: 'phone_format'}} </span>
                      <!--&lt;!&ndash;displayPhoneFormat&ndash;&gt;-->
                    </div>
                  </div>
                  <mat-divider [vertical]="true"></mat-divider>
                  <div class="pull-left" style="padding-left: 50px;padding-top: 15px">
                    <div class="clearfix" style="margin-bottom: -20px">
                    </div>
                    <div class="clearfix" style="margin-bottom: -20px">
                    </div>
                    <div class="clearfix" style="margin-bottom: -20px">
                    </div>
                  </div>
                  <div class="statusAlign" style="padding-top: 10px">
                    <div class="clearfix paydue">
                      <p class="light-font pull-left">RMA # :</p>
                      <p class="DateRight"> {{rmaDetList?.rmaNo}} </p>
                    </div>
                    <div class="clearfix">
                      <div class="lightFont">{{'rma.rma-detail.status' | translate}}:
                        <span class="tag-btn f-r"
                          [ngClass]=getClassByStatus()>
                          {{rmaStatus}} </span>
                      </div>
                    </div>
                    <div class="clearfix paydue">
                    </div>
                    <div class="divider"></div>
                    <div class="clearfix paydue">
                    </div>
                    <div class="divider"></div>
                  </div>
                </div>
                <div style="padding-bottom: 21px;">
                  <table mat-table [dataSource]="dataSource" class="tableHead">
                    <ng-container matColumnDef="item_name">
                      <th mat-header-cell *matHeaderCellDef class="light-font tableRow">{{ 'rma.rma-form.itemName' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.itemName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="item_desc">
                      <th mat-header-cell *matHeaderCellDef class="light-font tableRow">{{ 'rma.rma-form.description' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table tableColumn" style="max-width: 290px;">
                        {{element.itemDesc}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell *matHeaderCellDef class="light-font tableRow">{{ 'rma.rma-form.qty' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.quantity}}</td>
                    </ng-container>
                    <ng-container matColumnDef="reason">
                      <th mat-header-cell *matHeaderCellDef class="light-font tableRow">{{ 'rma.rma-form.reason' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn">
                        {{element.reason}}</td>
                    </ng-container>
                    <ng-container matColumnDef="requested_quantity">
                      <th mat-header-cell *matHeaderCellDef class="light-font tableRow">{{ 'rma.rma-form.reqQty' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.reqQuantity}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>

  </table>
  <div class="view_footer" style="padding-top:5px;">
    <footer class="footer">
      <hr>
      <div class="page">
        <div class="bottomFooter">
          <p class="leftFooter">{{configService.companyEmail}}</p>
        </div>
        <div class="centerFooter">
          <p class="leftFooter">
            {{configService.companyAddress}},{{configService.companyCity}},{{configService.companyState ? configService.companyState + ',' : ''}}{{configService.companyZipCode}},{{configService.companyCountry}}
            <br>{{configService.companyPhone| commonDate: 'phone_format' }}</p>
        </div>
        <div class="rightFooter">
          <p class="leftFooter">
            {{configService.companyWebsite ? configService.companyWebsite : configService.companyDomain}}</p>
        </div>
      </div>
    </footer>
  </div>
</div>
<app-spinner></app-spinner>
