  <div>
    <div class="close-div"><span style="cursor: pointer;" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></span> </div>
    <div class="add-company-dialog">

<div class="subscriptionPage clearfix">
  <div class="subscription_box" *ngIf="permissionService.subscription.view || configService.userIsOwner === 1">
    <div class="inv-sub-detail-content clearfix">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="radioChange($event)">
            <mat-tab label="Monthly">
                <div class="plan-text">Pricing shown below are according to <b>Monthly</b> plan</div>
                <div>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table">
                      <ng-container matColumnDef="item_name">
                        <th mat-header-cell *matHeaderCellDef>App</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
                      </ng-container>
                      <ng-container matColumnDef="active_users">
                        <th mat-header-cell *matHeaderCellDef>Number of Users</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
                          {{element.numberOfUsers}}</td>
                      </ng-container>
                      <ng-container matColumnDef="cost_pupm">
                        <th mat-header-cell *matHeaderCellDef>Cost per User per Month</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
                          {{element.cost_per_u_p_m}}</td>
                      </ng-container>
                      <ng-container matColumnDef="cost_pm">
                        <th mat-header-cell *matHeaderCellDef>Cost per Month</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
                          {{element.cost_per_month}}</td>
                      </ng-container>
                      <ng-container matColumnDef="row_tax">
                        <th mat-header-cell *matHeaderCellDef>Tax</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                          {{element.itemTax}}</td>
                      </ng-container>
                      <ng-container matColumnDef="row_cost">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                          {{element.total_row_cost}}</td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
            </mat-tab>
            <mat-tab label="Yearly">
                <div class="plan-text">Pricing shown below are according to <b >Yearly</b> plan</div>
              <div>
                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table">
                    <ng-container matColumnDef="item_name">
                      <th mat-header-cell *matHeaderCellDef>App</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="active_users">
                      <th mat-header-cell *matHeaderCellDef>Number of Users</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
                        {{element.numberOfUsers}}</td>
                    </ng-container>
                    <ng-container matColumnDef="cost_pupm">
                      <th mat-header-cell *matHeaderCellDef>Cost per User per Month</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
                        {{element.cost_per_u_p_m}}</td>
                    </ng-container>
                    <ng-container matColumnDef="cost_pm">
                      <th mat-header-cell *matHeaderCellDef>Cost per Month</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
                        {{element.cost_per_month}}</td>
                    </ng-container>
                    <ng-container matColumnDef="row_tax">
                      <th mat-header-cell *matHeaderCellDef>Tax</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                        {{element.itemTax}}</td>
                    </ng-container>
                    <ng-container matColumnDef="row_cost">
                      <th mat-header-cell *matHeaderCellDef>Total</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                        {{element.total_row_cost}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
            </mat-tab>
          </mat-tab-group>
      <!-- <div class="top_lable_outer">
        <div class="top_lable text-center">
          <div class="subscription-plan">
            <input type="radio" id="control_01" name="participants" name="select" (change)="radioChange(1)" [value]="1"
              [checked]="true">
            <label class="label-check" for="control_01">
              <h5>Monthly</h5>
            </label>
          </div>
        </div>
        <div class="top_lable text-center">
          <div class="subscription-plan">
            <input type="radio" id="control_03" name="select" (change)="radioChange(2)" [value]="2">
            <label class="label-check" for="control_03">
              <h5>Yearly</h5>
            </label>
          </div>
        </div>
      </div> -->
 

      <!-- <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table">
          <ng-container matColumnDef="item_name">
            <th mat-header-cell *matHeaderCellDef>App</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="active_users">
            <th mat-header-cell *matHeaderCellDef>Number of Users</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
              {{element.numberOfUsers}}</td>
          </ng-container>
          <ng-container matColumnDef="cost_pupm">
            <th mat-header-cell *matHeaderCellDef>Cost per User per Month</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
              {{element.cost_per_u_p_m}}</td>
          </ng-container>
          <ng-container matColumnDef="cost_pm">
            <th mat-header-cell *matHeaderCellDef>Cost per Month</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
              {{element.cost_per_month}}</td>
          </ng-container>
          <ng-container matColumnDef="row_tax">
            <th mat-header-cell *matHeaderCellDef>Tax</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
              {{element.itemTax}}</td>
          </ng-container>
          <ng-container matColumnDef="row_cost">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
              {{element.total_row_cost}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div> -->
      <div class="inv-foot-detail clearfix">
        <div class="inv-notes col-md-7" *ngIf="isMonthly"><span class="text-danger">*</span> You are getting charged for <b>{{remainingDaysToEndMonth}} days</b> of this month & your monthly payment cycle will start at 1st of next month.</div>
        <div class="inv-payment-detail col-md-5 pull-right">
          <div class="inv-payment-sub-detail">
            <div>
              <div>
                <div class="left-panel">Sub Total</div>
                <div class="right-panel">{{sTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel">Tax</div>
                <div class="right-panel">{{displaytax | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel">Discount</div>
                <div class="right-panel">{{displaydiscount | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel last">Total</div>
                <div class="right-panel last">{{displaytotal | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay-now col-md-7">
          <button mat-raised-button class="btn-submit" (click)="subscriptionDialog()" >Pay Now To Start Using
            CodeNgine</button>
        </div>
      </div>
      
    </div>
  </div>
  <div class="background-clr" *ngIf="!permissionService.subscription.edit && configService.userIsOwner !== 1">
    <div class="subcError">
      <h2 style="text-align: center;">
        You do not have subscription permission<br/>
        Please contact your administrator to create or renew subscription<br/><br/>
        <i style="color: #f05050;" class="fa fa-exclamation-triangle fa-5x" aria-hidden="true"></i></h2>
    </div>
  </div>
</div>
    </div>
    </div>