<div  #invoice id="invCom" *ngIf="!error">
  <div class="view_header">
    <div class="themeColor clearfix preview-header" [ngClass]="themeData" >
      <img src="{{purchaseOrderDetail?.companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
      <mat-label *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor" [ngClass]="themeData" style="color: black">{{purchaseOrderDetail?.companyName}}</mat-label>
      <mat-label *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor" [ngClass]="themeData" style="color: white">{{purchaseOrderDetail?.companyName}}</mat-label>
    </div>
  </div>
  
  <section class="inv-main-content">
    <div id="inv-detail-content" class="viewComponent">
      <div class="custom-item-btn pull-right view-print hand-cursor" mat-button #tooltip="matTooltip" matTooltipPosition="above"
           matTooltip="Print Purchase" (click)="printDetail(purchaseOrderDetail?.purchaseOrderId,purchaseOrderDetail?.vendorName)">
        <i class="fas fa-print text-danger "></i>
      </div>
      <div class="custom-item-btn pull-right hand-cursor" mat-button #tooltip="matTooltip" matTooltipPosition="above"
           matTooltip="PDF" (click)="downloadPDF(purchaseOrderDetail?.purchaseOrderId,purchaseOrderDetail?.vendorName)">
        <i class="fa fa-file-pdf-o colorPDF view-pdf" ></i>
      </div>

      <div class="inv-sub-main-content">
        <div class="inv-sub-header">{{purchaseOrderDetail?.referenceNo}}
        </div>
        <div class="inv-sub-detail-content clearfix">
          <div class="all_details row tw-p-2">
            <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4 pull-left client_info">
              <div class="clearfix">
                <span class="pull-left"><h4>{{purchaseOrderDetail?.vendorName}}</h4></span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{purchaseOrderDetail?.address}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{purchaseOrderDetail?.city}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{purchaseOrderDetail?.country}}</span>
              </div>
              <div class="clearfix address" *ngIf="purchaseOrderDetail?.phone">
                <span class="pull-left light-font">Phone: {{purchaseOrderDetail?.phone | commonDate: 'phone_format'}}</span>
              </div>
            </div>

            <div class="col-lg-4 col-xs-4 col-md-4 col-sm-4 br pv hidden-print client_info" style="padding-top: 10px;">
              <div class="clearfix address" *ngIf="purchaseOrderDetail?.clientName">
                <p class="pull-left light-font" *ngIf="purchaseOrderDetail?.clientName !== ''" style="padding-right: 5px">
                  Client: </p>
                <p class="pull-left ml"> {{ purchaseOrderDetail?.clientName}}</p>
              </div>
              <div class="clearfix address">
                <p class="pull-left light-font" style="padding-right: 5px" *ngIf="purchaseOrderDetail?.projectName || purchaseOrderDetail?.bugTitle || purchaseOrderDetail?.ticketCode">
                  Related To: </p>
                <p class="pull-left ml" *ngIf="purchaseOrderDetail?.projectName !== ''">
                  {{purchaseOrderDetail?.projectName}}
                </p>
                <p class="pull-left ml" *ngIf="purchaseOrderDetail?.bugTitle !== ''">
                  {{purchaseOrderDetail?.bugTitle}}
                </p>
                <p class="pull-left ml" *ngIf="purchaseOrderDetail?.ticketCode !== ''">
                  {{purchaseOrderDetail?.ticketCode}}</p>
              </div>
            </div>

            <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4 pull-right p-t-10 client_status_detail">
              <div class="clearfix">
                <div class="light-font">Purchase Status:
                  <span *ngIf="status === 'ACCEPTED' || status === 'PAID'" class="tag-btn successButton f-r"> {{status }}</span>
                  <span *ngIf="status === 'RECEIVED' || status === 'PARTIALLY-RECEIVED'" class="tag-btn voiletButton f-r po-status"> {{status }}</span>
                  <span *ngIf="status === 'PENDING'" class="tag-btn warningButton f-r"> {{status }}</span>
                  <span *ngIf="status === 'REJECTED'" class="tag-btn secondaryButton f-r"> {{status }}</span>
                </div>
              </div>
              <div class="clearfix invdate">
                <p class="pull-left light-font">Valid Until</p>
                <p class="pull-right mr">{{purchaseOrderDetail?.dueDate | commonDate : 'date' }}</p>
              </div>
              <div class="divider"></div>
            </div>
          </div>

          <div class="inv-line"></div>
          <table mat-table [dataSource]="dataSource" class="inv-table">
            <ng-container matColumnDef="item_name">
              <th mat-header-cell *matHeaderCellDef class="light-font">Item Name
              </th>
              <td mat-cell *matCellDef="let element"> {{element.itemName}}</td>
            </ng-container>
            <ng-container matColumnDef="item_desc">
              <th mat-header-cell *matHeaderCellDef class="light-font">Description</th>
              <td mat-cell *matCellDef="let element" class="inv-item-table" style="max-width: 290px;">
                {{element.itemDesc}}
              </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="light-font">Qty
              </th>
              <td mat-cell *matCellDef="let element"> {{element.quantity}}</td>
            </ng-container>
            <ng-container matColumnDef="unit_price">
              <th mat-header-cell *matHeaderCellDef class="light-font">Unit Price
              </th>
              <td mat-cell *matCellDef="let element"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
            </ng-container>
            <ng-container matColumnDef="subTotal">
              <th mat-header-cell *matHeaderCellDef class="light-font">Sub Total
              </th>
              <td mat-cell *matCellDef="let element"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="inv-line"></div>
          <div class="inv-foot-detail row">
            <div class="inv-notes col-sm-7 col-md-7 col-xs-7 col-lg-7" style="padding-left: 15px">
              <div class="left-notes tinyClass" [innerHTML]="purchaseOrderDetail?.notes"></div>
            </div>
            <div class="inv-payment-detail col-sm-5 col-md-5 col-xs-5 col-lg-5" style="padding-right: 13px">
              <div class="inv-payment-sub-detail">
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                    <div class="right-panel">{{purchaseOrderDetail?.subTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                    <div class="right-panel">{{purchaseOrderDetail?.taxTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                    <div class="right-panel">{{purchaseOrderDetail?.discountTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">Total</div>
                    <div class="right-panel">{{purchaseOrderDetail?.totalAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">Payment</div>
                    <div class="right-panel">{{purchaseOrderDetail?.payment | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel baldue">{{ 'invoices.invoice-detail.balDue' | translate }}</div>
                    <div *ngIf="invoiceDetList?.discount !== 100; then content2 else other_content2"></div>
                    <ng-template #content2>
                      <div class="right-panel baldueright">{{purchaseOrderDetail?.balanceDue | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                    </ng-template>
                    <ng-template #other_content2>
                      <div class="right-panel baldueright">0.00</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="page-footer view_footer" style="padding-top:5px;">
    <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co" target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
      <p class="alignright">{{version}}</p>
    </div>
  </footer>
</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px">Please check your entered Id.</p>
  </div>
</div>

<div id="print" [hidden]="!show">
  <table style="width: 100%">
    <tbody>
    <tr>
      <td>
        <div >
          <div class="inv-sub-main-content">
            <div class="waterMark">
              <p  class="styleWtareMarkGreen" *ngIf="status === 'ACCEPTED' || status === 'PAID'">
                {{status}}</p>
              <p  class="styleWtareMarkOrange" *ngIf="status === 'PENDING'">
                {{status}}</p>
              <p  class="styleWtareMarkSkyblue" *ngIf="status === 'RECEIVED' || status === 'PARTIALLY-RECEIVED'">
                {{status}}</p>
              <p  class="styleWtareMarkSecondary" *ngIf="status === 'REJECTED'">
                {{status}}</p>
            </div>
            <div class="themeColor" style="background-color: white">
              <div style="display: inline-block">
                <img src="{{purchaseOrderDetail?.companyLogo}}"  width="90px;" style="height: 60px;"
                     onError="this.src='/assets/img/default_company_logo.png'"> </div>
              <div class="headerName themeColor ">{{purchaseOrderDetail?.companyName}}</div>
            </div>
            <div class="inv-line"></div>
            <div class="inv-sub-detail-content clearfix">
              <div style="min-height: 200px">
                <div class="addressAlign">
                  <div class="clearfix">
                    <a class="pull-left"><h4>{{purchaseOrderDetail?.vendorName}}</h4></a>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{purchaseOrderDetail?.address}}</span>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{purchaseOrderDetail?.city}}</span>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{purchaseOrderDetail?.country}}</span>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font" *ngIf="purchaseOrderDetail?.phone">Phone: {{purchaseOrderDetail?.phone | commonDate: 'phone_format'}}</span>
                  </div>
                </div>

                <div class="pull-left" style="margin-left: 14px;padding-top: 15px;width: 35%;">
                  <div class="clearfix" *ngIf="purchaseOrderDetail?.clientName" style="margin-bottom: -20px">
                    <p class="nameAlign" *ngIf="clientName !== ''" style="padding-right: 5px;font-weight: lighter">
                      Client: </p>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter"> {{purchaseOrderDetail?.clientName}}</a>
                  </div>
                  <div class="clearfix" *ngIf="purchaseOrderDetail?.projectName || purchaseOrderDetail?.bugTitle || purchaseOrderDetail?.ticketCode" style="margin-bottom: -20px">
                    <p class="nameAlign" style="padding-right: 5px;font-weight: lighter">
                      Related To: </p>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="purchaseOrderDetail?.projectName">
                      {{purchaseOrderDetail?.projectName}}
                    </a>
                   <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="purchaseOrderDetail?.bugTitle">
                      {{purchaseOrderDetail?.bugTitle}}
                    </a>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="purchaseOrderDetail?.ticketCode">
                      {{purchaseOrderDetail?.ticketCode}}</a>
                  </div>
                </div>

                <div class="statusAlign" style="padding-top: 10px">
                  <div class="clearfix invdate">
                    <p class="pull-left light-font">Purchase Order # :</p>
                    <p class="DateRight">{{purchaseOrderDetail?.referenceNo}}</p>
                  </div>
                  <div class="clearfix">
                    <div class="lightFont">Purchase Status:
                      <span *ngIf="status === 'ACCEPTED' || status === 'PAID'" class="common-button successButton f-r"> {{status }}</span>
                      <span *ngIf="status === 'RECEIVED' || status === 'PARTIALLY-RECEIVED'" class="common-button voiletButton f-r"> {{status }}</span>
                      <span *ngIf="status === 'PENDING'" class="common-button warningButton f-r"> {{status }}</span>
                      <span *ngIf="status === 'REJECTED'" class="common-button secondaryButton f-r"> {{status }}</span>
                    </div>
                  </div>
                  <div class="clearfix invdate">
                    <p class="pull-left light-font">Valid Until</p>
                    <p class="DateRight">{{purchaseOrderDetail?.dueDate | commonDate : 'date' }}</p>
                  </div>
                  <div class="divider"></div>
                </div>
              </div>

              <div style="padding-bottom: 21px;" class="item_table_div">
                <table mat-table [dataSource]="dataSource" class="tableHead">
                  <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Item Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.itemName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="item_desc">
                    <th mat-header-cell *matHeaderCellDef class=" tableRow light-font">Description</th>
                    <td mat-cell *matCellDef="let element" class="inv-item-table tableColumn" style="max-width: 290px;">
                      {{element.itemDesc}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Qty
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.quantity}}</td>
                  </ng-container>
                  <ng-container matColumnDef="unit_price">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Unit Price
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="subTotal">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Sub Total
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
              <div class="inv-foot-detail">
                <div class=" notes" style="padding-left: 15px">
                  <div class="inv-notes" [innerHTML]="purchaseOrderDetail?.notes"></div>
                </div>
                <div class="inv-payment-detail col-sm-4 col-md-4 col-xs-4 col-lg-4" style="padding-right: 13px">
                  <div class="inv-payment-sub-detail">
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                        <div class="right-panel">{{purchaseOrderDetail?.subTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                        <div class="right-panel">{{purchaseOrderDetail?.taxTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                        <div class="right-panel">{{purchaseOrderDetail?.discountTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">Total</div>
                        <div class="right-panel">{{purchaseOrderDetail?.totalAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">Payment</div>
                        <div *ngIf="purchaseOrderDetail?.discount !== 100; then content1 else other_content1"></div>
                        <ng-template #content1>
                          <div class="right-panel">{{purchaseOrderDetail?.payment | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content1>
                          <div class="right-panel">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel baldue">{{ 'invoices.invoice-detail.balDue' | translate }}</div>
                        <div *ngIf="purchaseOrderDetail?.discount !== 100; then content2 else other_content2"></div>
                        <ng-template #content2>
                          <div class="right-panel baldueright">{{purchaseOrderDetail?.balanceDue | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content2>
                          <div class="right-panel baldueright">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <td>
        <div class="page-footer-space"></div>
      </td>
    </tr>
    </tfoot>

  </table>
  <div class="view_footer" style="padding-top:5px;">
    <footer class="footer">
      <hr>
      <div class="page">
        <div class="bottomFooter">
          <p class="leftFooter">{{purchaseOrderDetail?.companyEmail}}</p>
        </div>
        <div class="centerFooter">
          <p class="leftFooter"> {{purchaseOrderDetail?.companyAddress}},{{purchaseOrderDetail?.companyCity}},{{purchaseOrderDetail?.companyState ? purchaseOrderDetail?.companyState + ',' : ''}}{{purchaseOrderDetail?.companyZipCode}},{{purchaseOrderDetail?.companyCountry}} <br>{{purchaseOrderDetail?.companyPhone}}</p>
        </div>
        <div class="rightFooter">
          <p class="leftFooter">{{purchaseOrderDetail?.companyWebsite ? purchaseOrderDetail?.companyWebsite : purchaseOrderDetail?.companyDomain}}</p>
        </div>
      </div>
    </footer>
  </div>

</div>
<app-spinner></app-spinner>
