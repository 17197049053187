<!-- Footer -->
<div class="clear"></div>
<footer class="page-footer">

  <!-- Copyright -->
  <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co" target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
    <p class="alignright">{{version}}</p>
  </div>
  <!--<div class="footer-copyright text-right">Version 4.0</div>-->
  <!-- Copyright -->
</footer>
<!-- Footer -->
