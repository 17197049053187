<div class="docs-module">
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" #drawer opened class="sidenav mat-elevation-z8"
      fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'">
      <a routerLinkActive="active-link" routerLink="/admin/dashboard">
      <div class="navbar-header sidebar-navbar-brand mainHeader" fxLayout="column"
        fxLayoutAlign="center" fxLayoutGap="7px">
         <span class="lead navbar-brand logoHeader header">CodeNgine</span>
      </div>
    </a>
      <div class="docs-sidenav">
        <mat-nav-list class="mat-nav-list mat-list-base" id="matnavList" dense>
          <mat-list-item class="mat-list-item menu-list-item" >
            <div class="mat-list-item-content" class="Menu" >
              <a class="sidenavelement" routerLinkActive="tabSection"  id="firstId" routerLink="/admin/docs/platform-docs"  (click)="tabClick($event,'firstId')" >
                <mat-icon >notes</mat-icon><span >Platform Docs</span>
              </a>
            </div>
          </mat-list-item>
          <mat-list-item class="mat-list-item menu-list-item">
            <div class="mat-list-item-content" class="Menu" >
              <a class="sidenavelement " routerLinkActive="tabSection" id="secondId" routerLink="/admin/docs/setting-up-environment"   (click)="tabClick($event,'secondId')">
                <mat-icon >settings_applications</mat-icon><span> Environment Settings</span>
              </a>
            </div>
          </mat-list-item>
          <mat-list-item class="mat-list-item menu-list-item">
            <div class="mat-list-item-content" class="Menu">
              <a class="sidenavelement" routerLinkActive="tabSection" routerLink="/admin/docs/technology-stack"  id="thirdId" (click)="tabClick($event,'thirdId')">
                <mat-icon >whatshot</mat-icon><span>Technology Stack</span>
              </a>
            </div>
          </mat-list-item>
              <mat-list-item class="mat-list-item menu-list-item">
                <div class="mat-list-item-content" class="Menu">
                  <a class="sidenavelement" routerLinkActive="tabSection" routerLink="/admin/docs/ui-docs"  id="fourthId" (click)="tabClick($event,'fourthId')" >
                    <mat-icon >assignment</mat-icon><span>UI Docs</span>
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item class="mat-list-item menu-list-item">
                <div class="mat-list-item-content" class="Menu">
                  <a class="sidenavelement" routerLinkActive="tabSection" routerLink="/admin/docs/api-docs" id="fifthId"(click)="tabClick($event,'fifthId')">
                    <mat-icon>event_note</mat-icon><span>API Docs</span>
                  </a>
                </div>
              </mat-list-item>
              <mat-list-item class="mat-list-item menu-list-item">
                <div class="mat-list-item-content">
                  <a class="sidenavelement" routerLinkActive="tabSection" routerLink="/admin/docs/release-notes"  id="sixId" (click)="tabClick($event,'sixId')">
                    <mat-icon >description</mat-icon><span>Release Notes</span>
                  </a>
                </div>
              </mat-list-item>

                <mat-accordion multi="true" class="Testexapnsionpanel">
                  <mat-expansion-panel class="no-shadow my-0" #first >
                     <mat-expansion-panel-header fxLayout="row" class="expansion-panel">
                       <mat-panel-title>
                         <mat-list-item>
                         <div class="mat-list-item-content">
                           <a class="sidenavelement" [ngClass]="{'tabSection': testCase.isActive || testCaseDetail.isActive}"  id="sevenId" (click)="tabClick($event,'sevenId')">
                             <mat-icon >beach_access</mat-icon><span>Test and QA </span>
                           </a>
                           </div>
                         </mat-list-item>
                       </mat-panel-title>
                     </mat-expansion-panel-header>
                     <mat-nav-list class="mat-nav-list mat-list-base" id="matnavList" style="padding-top: 0px;" dense>
                       <mat-list-item class="mat-list-item menu-list-item">
                         <div class="mat-list-item-content">
                           <a class="sidenavelement" [ngClass]="{'childSection': testCaseDetail.isActive}" routerLinkActive="childSection" #testCase="routerLinkActive" routerLink="/admin/docs/test-and-qa/test-cases" class="mainMenu" id="eightId" (click)="subtabClick($event,'eightId')">
                             <mat-icon >playlist_add_check</mat-icon><span>Tests Cases</span>
                           </a>
                           <a #testCaseDetail="routerLinkActive" style="display: none;" routerLinkActive>Tests Cases</a>
                         </div>
                       </mat-list-item>
                       <!-- <mat-list-item class="mat-list-item menu-list-item">
                         <div class="mat-list-item-content">
                           <a class="sidenavelement" class="mainMenu" id="nineId" (click)="subtabClick($event,'nineId')">
                             <mat-icon >code</mat-icon><span>Code Quality</span>
                           </a><br>
                         </div>
                       </mat-list-item> -->
                     </mat-nav-list>
                   </mat-expansion-panel>

                 </mat-accordion>
      </mat-nav-list>
      </div>
    </mat-sidenav>


    <mat-sidenav-content [ngStyle]="{'margin-left': contentMargin}">
      <mat-toolbar color="primary" class="topbar telative">

        <div class="header_site_name">
          <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon class="header-icon">menu</mat-icon>
          </button>
          <mat-label> CodeNgine Docs </mat-label>
        </div>
        <div class="header_search_call">
        </div>
        <span class="toolbar-spacer"></span>
        <button mat-button class="btn-color">
          <mat-icon class="pos">keyboard_arrow_left</mat-icon>
          <a routerLinkActive="active-link" routerLink="/admin/dashboard">
          <span>Back to Dashboard</span>
          </a>
        </button>
       </mat-toolbar>
    </mat-sidenav-content>
    <mat-sidenav-content class="page-wrapper">

      <div class="page-content">
          <!-- <div style="width: 100%; height: auto;">
              <app-breadcrumb></app-breadcrumb>
            </div> -->

        <router-outlet></router-outlet>
        <!-- <router-outlet name='api-docs'></router-outlet> -->

        <div class="clear"></div>
          <footer class="page-footer">

            <!-- Copyright -->
          <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="https://codengine.co" target="_blank">CodeNgine Technologies{{currYear}}.</a>All rights reserved.
          <!-- <div class="col-md-12 text-right"> -->
            <p class="alignright">{{version}}</p>
          <!-- </div> -->
      </div>
  <!--<div class="footer-copyright text-right">Version 4.0</div>-->
  <!-- Copyright -->
</footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
<app-spinner></app-spinner>






